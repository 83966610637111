import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsers } from "../../../api/admin";
import { Spinner } from "../../../components/common/Spinner";
import { TUser } from "../../../lib/types";
import { AdminWrapper } from "../../Wrapper.admin";

export const AdminUsers: React.FC = () => {
	const [users, setUsers] = useState<Array<TUser> | null>(null);
	const [limitHit, setLimitHit] = useState<boolean>(false);
	const navigate = useNavigate();
	const search = useLocation().search;
	let page = new URLSearchParams(search).get("page");

	if (page == "1") {
		page = null;
	}

	useEffect(() => {
		(async () => {
			const limit = 15;
			let resp;
			if (page) {
				resp = await getUsers(parseInt(page));
			} else {
				resp = await getUsers();
			}
			if (resp.users.length < limit) {
				setLimitHit(true);
			}
			setUsers(resp.users);
		})();
	}, []);

	return (
		<AdminWrapper title="Admin" name="Users">
			{users ? (
				<>
					<table className="w-full table-auto shadow max-h-[1065px]">
						<thead>
							<tr className="bg-white text-left h-10">
								<th>#</th>
								<th>ID</th>
								<th>Name</th>
								<th>Email</th>
								<th>Enrolments</th>
								<th>Created At</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user, index) => (
								<tr className="bg-slate-50 h-10 even:bg-slate-300 ">
									<td>{index + 1}</td>
									<td>
										<motion.span
											initial={{ x: 0 }}
											whileTap={{ x: 15 }}
											className="block cursor-pointer text-blue-500 hover:text-blue-600"
											onClick={() =>
												navigator.clipboard.writeText(
													user.id
												)
											}
										>
											Copy ID
										</motion.span>
									</td>
									<td>{user.name}</td>
									<td>{user.email}</td>
									<td>0</td>
									<td>
										{new Date(
											parseInt(user.created_at)
										).toLocaleString("en-AU")}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="container flex justify-center mx-auto mt-8">
						<ul className="flex bg-white text-slate-700">
							{page ? (
								<>
									<li>
										<button
											onClick={() =>
												(window.location.href = `/admin/users?page=${
													parseInt(page as any) - 1
												}`)
											}
											className="h-10 px-5  border border-r-0 border-slate-600 hover:bg-slate-100"
										>
											Prev
										</button>
									</li>
									<li>
										<button
											onClick={() =>
												(window.location.href = `/admin/users?page=${
													parseInt(page as any) - 1
												}`)
											}
											className="h-10 px-5 border border-r-0 border-slate-600 hover:bg-slate-100"
										>
											{parseInt(page) - 1}
										</button>
									</li>
								</>
							) : null}
							<li>
								<button
									className={
										"h-10 px-5 border border-r-0 border-slate-600 " +
										`${
											page
												? `${
														parseInt(page) > 1
															? "bg-slate-600 text-white"
															: ""
												  }`
												: "bg-slate-600 text-white"
										}`
									}
								>
									{page ? page : 1}
								</button>
							</li>
							{!limitHit ? (
								<>
									<li>
										<button
											onClick={() =>
												(window.location.href = `/admin/users?page=${
													page
														? parseInt(
																page as any
														  ) + 1
														: 2
												}`)
											}
											className={
												"h-10 px-5 border border-r-0 border-slate-600 "
											}
										>
											{page ? parseInt(page) + 1 : 2}
										</button>
									</li>
									<li>
										<button
											onClick={() =>
												(window.location.href = `/admin/users?page=${
													page
														? parseInt(
																page as any
														  ) + 1
														: 2
												}`)
											}
											className="h-10 px-5 border border-slate-600 hover:bg-slate-100"
										>
											Next
										</button>
									</li>
								</>
							) : null}
						</ul>
					</div>
				</>
			) : (
				<div className="flex justify-center items-center h-full">
					<Spinner className="w-20 h-full" />
				</div>
			)}
		</AdminWrapper>
	);
};
