import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import { courseOverviewReducer } from "./reducers/courseOverview";
import { userReducer } from "./reducers/user";

const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
	user: userReducer,
	courseOverview: courseOverviewReducer,
});

export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware())
);
