import React, { useRef, useState } from "react";
import { AdminWrapper } from "../../Wrapper.admin";
import imageCompression from "browser-image-compression";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { createInstructor } from "../../../api/admin";

export const AdminCreateInstructor: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [message, setMessage] = useState<{ bg: string; msg: string } | null>(
    null
  );
  const loadingRef = useRef<LoadingBarRef>(null);

  const handleImageSelect = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setMessage(null);

    if (target.files && target.files[0]) {
      if (target.files[0]["type"].split("/")[0] !== "image") {
        e.preventDefault();

        return setMessage({
          bg: "bg-red-500",
          msg: "Invalid image selected",
        });
      }

      setSelectedImage(URL.createObjectURL(target.files[0]));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(null);
    const form = e.target as any;

    const payload: {
      name?: string;
      avatar?: string;
      about?: string;
      email?: string;
    } = {};

    payload.name = form.name.value;
    payload.about = form.about.value;
    payload.email = form.email.value;

    if (form.avatar.files[0]["type"].split("/")[0] !== "image") {
      return setMessage({
        bg: "bg-red-500",
        msg: "Invalid image selected",
      });
    }
    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
    };
    const compressedImage = await imageCompression(
      form.avatar.files[0],
      options
    );
    const imageDataURI = await imageCompression.getDataUrlFromFile(
      compressedImage
    );
    payload.avatar = imageDataURI;

    try {
      loadingRef.current?.continuousStart(10, 15);
      await createInstructor(payload);
      loadingRef.current?.complete();
      window.location.href = "/admin/instructors";
    } catch (e) {
      setMessage({ bg: "bg-red-500", msg: "There was an error" });
    } finally {
      loadingRef.current?.complete();
    }
  };

  return (
    <AdminWrapper title="Admin" name="Create a new instructor">
      <LoadingBar color="#f11946" ref={loadingRef} />
      <form onSubmit={handleSubmit} className="p-6 flex flex-col gap-6">
        {message && (
          <div
            className={`${message?.bg} rounded-md text-center py-4 text-lg text-white mb-8 container w-full`}
          >
            {message?.msg}
          </div>
        )}
        <div>
          <p className="font-semibold">Name</p>
          <input
            type="text"
            name="name"
            onChange={(e) => e.currentTarget.value}
            className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
            required
          />
        </div>
        <div>
          <p className="font-semibold">About</p>
          <input
            type="text"
            name="about"
            onChange={(e) => e.currentTarget.value}
            className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
            required
          />
        </div>
        <div>
          <p className="font-semibold">Email</p>
          <input
            type="email"
            name="email"
            onChange={(e) => e.currentTarget.value}
            className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
            required
          />
        </div>
        <div>
          <p className="font-semibold">Profile Image</p>
          <div className="flex items-center gap-4">
            <div className="bg-gray-50">
              <input
                required
                type="file"
                name="avatar"
                accept="image/png, image/jpeg"
                onChange={handleImageSelect}
                id="avatarInp"
                className="block w-full
                                            px-3
                                            py-1.5
                                            text-base
                                            font-normal
                                            text-gray-700
                                            bg-gray-50 bg-clip-padding
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            </div>
            {selectedImage ? (
              <>
                <img
                  src={selectedImage}
                  className="h-[256px] mt-3 border border-gray-700"
                  alt=""
                />
              </>
            ) : null}
          </div>
          <button className="mt-6 w-60 px-4 py-2 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md">
            Submit Instructor
          </button>
        </div>
      </form>
    </AdminWrapper>
  );
};
