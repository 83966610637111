import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEnrolledCourseData } from "../../../api/course";
import { CourseNavbar } from "../../../components/course/Navbar";
import { OverviewContent } from "../../../components/course/OverviewContent";
import { OverviewInstructor } from "../../../components/course/OverviewInstuctor";
import { OverviewSidebar } from "../../../components/course/OverviewSidebar";
import { TContent, TCourse, TInstructor } from "../../../lib/types";
import { newlineText } from "../../../lib/utils";
import { NotFound } from "../../common/NotFound";
import { Wrapper } from "../../Wrapper";
import analytics from "../../../lib/analytics";
import { Thumbnail } from "../../../components/common/Thumbnail";

const pageTitle = "Course Overview";

export const CourseOverview: React.FC = () => {
  const { id } = useParams();
  const [content, setContent] = useState<Array<TContent> | null>(null);
  const [course, setCourse] = useState<TCourse | null>(null);
  const [instructor, setInstructor] = useState<TInstructor | null>(null);
  const selectedTab: string = useSelector(
    (state) => (state as any).courseOverview.tab
  );
  const [notFound, setNotFound] = useState<boolean>(false);

  analytics.usePageHitEffect(pageTitle);

  useEffect(() => {
    (async () => {
      try {
        const data = await getEnrolledCourseData(id as string);

        analytics.viewEnrolledCourse(data.course.name);

        setContent(data.content);
        setCourse(data.course);
        setInstructor(data.instructor);
      } catch (e) {
        setNotFound(true);
      }
    })();
  }, [id]);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <Wrapper title={pageTitle}>
      {content && instructor && course ? (
        <>
          <CourseNavbar />
          <div
            style={{ height: "calc(100vh - 80px)" }}
            className="flex flex-col md:flex-row bg-white overflow-y-scroll md:overflow-hidden"
          >
            <OverviewSidebar course={course} />
            <div className="block md:hidden">
              <Thumbnail courseID={course.id} />
              <h1 className="text-center pt-[25px] pb-16 font-semibold text-2xl">
                {course.name}
              </h1>
            </div>
            <div className="px-2 md:px-12 pb-16 md:pt-10 w-full md:overflow-y-scroll">
              {selectedTab === "content" ? (
                <>
                  <div className="mb-7 rounded-lg p-4">
                    <h1 className="text-2xl text-gray-700 font-semibold mb-4">
                      About Course
                    </h1>
                    <p> {newlineText(course.description)}</p>
                  </div>
                  <OverviewContent contents={content} />
                </>
              ) : (
                <OverviewInstructor instructor={instructor} />
              )}
            </div>
          </div>
        </>
      ) : null}
    </Wrapper>
  );
};
