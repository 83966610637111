import React, { useEffect, useState } from "react";
import { getPlatformStats } from "../../api/admin";
import { LatestEnrolments } from "../../components/admin/LatestEnrolments";
import { LatestPurchases } from "../../components/admin/LatestPurchases";
import { Spinner } from "../../components/common/Spinner";
import { AdminWrapper } from "../Wrapper.admin";

interface Stats {
	users: number;
	instructors: number;
	courses: number;
}

export const AdminHome: React.FC = () => {
	const [stats, setStats] = useState<Stats | null>(null);

	useEffect(() => {
		(async () => {
			setStats(await getPlatformStats());
		})();
	}, []);

	return (
		<AdminWrapper name="Home" title="Admin">
			<div className="px-8">
				{stats ? (
					<>
						<div className="flex justify-between gap-5 mt-10">
							<div className="p-8 text-white shadow-xl w-full bg-gradient-to-r from-red-500 to-rose-500 flex flex-col gap-6 relative">
								<p className="text-6xl font-bold">
									{stats.users.toLocaleString("en-AU")}
								</p>
								<p className="text-2xl font-light mb-4">
									Users
								</p>
								<p className="text-6xl xl:text-9xl">
									<i className="fas fa-user-alt absolute bottom-0 right-0"></i>
								</p>
							</div>
							<div className="p-8 text-white shadow-xl w-full bg-gradient-to-r from-cyan-500 to-blue-500 flex flex-col gap-6 relative">
								<p className="text-6xl font-bold">
									{stats.courses.toLocaleString("en-AU")}
								</p>
								<p className="text-2xl font-light mb-4">
									Courses
								</p>
								<p className="text-6xl xl:text-9xl">
									<i className="fas fa-chalkboard-teacher absolute bottom-0 right-0"></i>
								</p>
							</div>
							<div className="p-8 text-white shadow-xl w-full bg-gradient-to-r from-violet-500 to-indigo-500 flex flex-col gap-6 relative">
								<p className="text-6xl font-bold">
									{stats.instructors.toLocaleString("en-AU")}
								</p>
								<p className="text-2xl font-light mb-4">
									Instructors
								</p>
								<p className="text-6xl xl:text-9xl">
									<i className="fas fa-users-cog absolute bottom-0 right-0"></i>
								</p>
							</div>
						</div>
						<div className="flex w-full justify-between mt-12 gap-10">
							<LatestPurchases />
							<LatestEnrolments />
						</div>
					</>
				) : (
					<div className="flex justify-center items-center h-full">
						<Spinner className="w-20 h-full" />
					</div>
				)}
			</div>
		</AdminWrapper>
	);
};
