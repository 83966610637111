import React from "react";
import { useLanguage } from "../../lib/hooks";

const questions = [
  {
    question: "How is BitShift different from other platforms?",
    answer:
      "Our platform goes beyond traditional course curricula by offering not only comprehensive concepts but also practical applications. We aren't here to make you depend on us, but to make you self sufficient so you can grow and continue learning on your own after you are done with our courses.",
  },
  {
    question: "What else does BitShift offer besides the courses?",
    answer:
      "Besides our catalogue of courses, we have a YouTube channel where we discuss and demonstrate specialized topics and practical use cases of various technology. If many people are having problems understanding something, we may make individual videos on those topics too.",
  },
  {
    question: "Why is BitShift free?",
    answer:
      "From the start, we wanted to make IT approachable to everyone. Many people are afraid to start learning because a lot of learning centers/platforms offer expensive courses which aren't affordable by most. BitShift wants to make it easier for everyone, and offer courses to be as affordable as possible.",
  },
  {
    question: "Will the courses always be free?",
    answer:
      "Majority of the courses on the platform will be free of cost. But once we start to grow and have much expenses to cover (including bringing new instructors), we may have to charge a bit of money for a few courses. But fear not! These are only for highly advanced courses, and we will ensure that they are affordable.",
  },
  {
    question: "How do I reach out?",
    answer:
      "If you have any questions, need help, want to contribute, or just want to reach out to us, you can send a mail at [bitshift@ahnafzamil.com].",
  },
];

export const FAQ: React.FC = () => {
  const langData = useLanguage();
  return (
    <section id="latest-yt-videos" className="bg-gray-50 pb-24 px-8">
      <div className="container mx-auto h-full text-gray-900">
        <h1 className="text-4xl font-bold text-gray-700 text-center pb-10">
          {langData.pages.home.faq_header}
        </h1>
        <p className="text-gray-600 text-center text-lg mb-16">
          {langData.pages.home.faq_sub}
        </p>
        {questions.map((qn, index) => (
          <div className="tab px-5 py-2 bg-white shadow-lg relative mb-2 rounded-md lg:w-[900px] mx-auto">
            <input
              type="radio"
              name="faq"
              id={`faq${index}`}
              className="appearance-none peer"
            />
            <label
              htmlFor={`faq${index}`}
              className="flex items-center cursor-pointer font-semibold text-lg after:content-['+'] after:absolute after:right-5 after:text-2xl     after:text-gray-400 hover:after:text-gray-950 peer-checked:after:transform peer-checked:after:rotate-45"
            >
              <h2 className=" w-8 h-8 bg-blue-300 text-white flex justify-center items-center rounded-sm mr-5">
                {index + 1}
              </h2>
              <h3>{qn.question}</h3>
            </label>
            <div className="mx-4 my-3 answer content mt-5 h-0 transition ease-in-out delay-300 overflow-hidden peer-checked:h-full">
              <p>{qn.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
