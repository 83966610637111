import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLanguage } from "../../lib/hooks";

const channelId = "UC7iAltl7Wv9ajEaMKazqYzQ";

export const YouTubeLatest: React.FC = () => {
  const [videos, setVideos] = useState<Array<any> | null>(null);
  const langData = useLanguage();

  useEffect(() => {
    (async () => {
      const feedEncodedURI = encodeURIComponent(
        "https://www.youtube.com/feeds/videos.xml?channel_id=" + channelId
      );
      const resp = await axios.get(
        "https://api.rss2json.com/v1/api.json?rss_url=" + feedEncodedURI
      );
      setVideos(resp.data.items.slice(0, 3));
    })();
  }, []);

  return (
    <section id="latest-yt-videos" className="bg-gray-50 pb-24">
      <div className="container mx-auto h-full text-gray-900">
        <h1 className="text-4xl font-bold text-gray-700 text-center pb-10">
          {langData.pages.home.new_youtube_header}
        </h1>
        <p className="text-gray-600 text-center text-lg mb-12">
          {langData.pages.home.new_youtube_sub}
        </p>
        <div className="text-gray-600 px-2 flex flex-wrap justify-center mx-auto items-center gap-5">
          {videos &&
            videos.map((vid) => {
              return (
                <>
                  <a
                    href={vid.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="min-h-[300px] border group transform duration-300 block w-[280px] lg:w-[320px] h-full bg-white transition duration-250 hover:bg-gray-200 cursor-pointer"
                  >
                    <img
                      src={vid.thumbnail.replace("hqdefault", "mqdefault")}
                      alt=""
                    />
                    <p className="p-4 text-ellipsis">{vid.title}</p>
                  </a>
                </>
              );
            })}
        </div>
      </div>
    </section>
  );
};
