import en from "../i8n/en.json";
import bn from "../i8n/bn.json";

export const useQueryParams = (query: string | null = null) => {
  const result: Record<string, string> = {};
  new URLSearchParams(query || window.location.search).forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const useLanguage = () => {
  // Get language file based on user's selected language
  let lang = localStorage.getItem("lang");

  switch (lang) {
    case "english":
      return en;
    case "bangla":
      return bn;
    default:
      localStorage.setItem("lang", "bangla"); // Default will be english
      return bn;
  }
};
