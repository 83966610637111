const config = {
  API_URL:
    process.env.NODE_ENV === "development"
      ? "//localhost:8888"
      : "//api.ahnafzamil.com/bitshift",
  CDN_URL: `https://bitshift-bd.s3.ap-south-1.amazonaws.com`,
  SUPPORT_EMAIL: "bitshift@ahnafzamil.com",
};

export default config;
