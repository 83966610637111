import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getContents } from "../../../api/admin";
import { Spinner } from "../../../components/common/Spinner";
import { TContent, TCourse } from "../../../lib/types";
import { AdminWrapper } from "../../Wrapper.admin";

export const AdminShowContents: React.FC = () => {
	const { id } = useParams();
	const [course, setCourse] = useState<TCourse | null>(null);
	const [contents, setContents] = useState<Array<TContent> | null>(null);

	useEffect(() => {
		(async () => {
			const data = await getContents(id as string);
			setCourse(data.course);
			setContents(data.content);
		})();
	}, []);

	return (
		<AdminWrapper
			title="Admin"
			name={"Contents for " + (course ? course.name : "")}
		>
			<button
				onClick={() =>
					(window.location.href = window.location.href + "/create")
				}
				className="absolute bottom-0 right-0 m-6 text-white text-2xl text-center rounded-full h-14 w-14 hover:bg-sky-800 bg-sky-700"
			>
				+
			</button>
			{contents && course ? (
				<>
					<table className="w-full table-auto shadow max-h-[1065px]">
						<thead>
							<tr className="bg-white text-left h-10">
								<th>#</th>
								<th>ID</th>
								<th>Title</th>
								<th>Description</th>
								<th>Video URL</th>
							</tr>
						</thead>
						<tbody>
							{contents.map((content, index) => (
								<tr className="bg-slate-50 h-10 even:bg-slate-300 ">
									<td>{index + 1}</td>
									<td>
										<motion.span
											initial={{ x: 0 }}
											whileTap={{ x: 15 }}
											className="block cursor-pointer text-blue-500 hover:text-blue-600"
											onClick={() =>
												navigator.clipboard.writeText(
													content.id
												)
											}
										>
											Copy ID
										</motion.span>
									</td>
									<td>
										<a
											className="cursor-pointer text-blue-500 hover:text-blue-600"
											href={`/admin/courses/${course.id}/content/${content.id}`}
										>
											{content.title}
										</a>
									</td>
									<td>
										{content.description.slice(0, 20)}...
									</td>
									<td>
										<a
											href={content.video_url}
											className="cursor-pointer text-blue-500 hover:text-blue-600"
										>
											{content.video_url.slice(0, 20)}...
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			) : (
				<div className="flex justify-center items-center h-full">
					<Spinner className="w-20 h-full" />
				</div>
			)}
		</AdminWrapper>
	);
};
