import React, { useEffect, useState } from "react";
import { getCourses } from "../../../api/course";
import { CourseCard } from "../../../components/common/Course";
import { Footer } from "../../../components/common/Footer";
import { Navbar } from "../../../components/common/Navbar";
import { Spinner } from "../../../components/common/Spinner";
import { TCourse } from "../../../lib/types";
import { Wrapper } from "../../Wrapper";
import analytics from "../../../lib/analytics";

const pageTitle = "All Courses";

export const ViewCourses: React.FC = () => {
  const [courses, setCourses] = useState<Array<TCourse> | null>(null);

  analytics.usePageHitEffect(pageTitle);

  useEffect(() => {
    (async () => {
      const data = await getCourses();
      setCourses(data);
    })();
  }, []);

  return (
    <Wrapper title={pageTitle}>
      <Navbar />
      <div className="container mx-auto w-9/12">
        <h1 className="text-center text-4xl my-16">All Courses</h1>
        <div className="mb-16 text-gray-600 flex flex-wrap justify-center mx-auto items-center gap-16">
          {courses ? (
            courses?.map((course) => <CourseCard course={course} />)
          ) : (
            <div className="flex justify-center items-center h-48 w-full">
              <Spinner className="w-20 h-full" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};
