import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TCourse } from "../../lib/types";
import { Thumbnail } from "../common/Thumbnail";

interface Props {
  course: TCourse;
}

export const OverviewSidebar: React.FC<Props> = ({ course }) => {
  const dispatch = useDispatch();
  const selectedTab: string = useSelector(
    (state) => (state as any).courseOverview.tab
  );

  return (
    <div className="hidden md:block text-gray-800 h-full w-[500px] bg-white shadow-xl border-r-2 relative">
      <Thumbnail courseID={course.id} />
      <h1 className="text-center p-[25px] font-semibold text-[1.125rem]">
        {course.name}
      </h1>
      <div className="border-t">
        <div
          onClick={() =>
            dispatch({ type: "SET_TAB", data: { tab: "content" } })
          }
          className={`${
            selectedTab === "content" ? "border-blue-900" : ""
          } cursor-pointer border-l-4 pl-6 py-5 hover:bg-gray-200 transition-all duration-400`}
        >
          <i className="fas fa-graduation-cap pr-3"></i> Course Content
        </div>
        <div
          onClick={() =>
            dispatch({
              type: "SET_TAB",
              data: { tab: "instructor" },
            })
          }
          className={`${
            selectedTab === "instructor" ? "border-blue-900" : ""
          } border-l-4 cursor-pointer pl-6 py-5 hover:bg-gray-200 transition-all duration-400`}
        >
          <i className="fas fa-chalkboard-teacher pr-3"></i> Your Instructor
        </div>
      </div>
      <p className="absolute bottom-0 text-center w-full text-xs pb-2 text-gray-500">
        Copyright &copy; K.M Ahnaf Zamil {new Date().getFullYear()}
      </p>
    </div>
  );
};
