import React from "react";
import { logoutUser } from "../../api/auth";
import logo from "../../assets/img/logo.png";
import backgroundSVG from "../../assets/svg/blurry-gradient.svg";

export const Sidebar: React.FC = () => {
  return (
    <div
      style={{ backgroundImage: `url(${backgroundSVG})` }}
      className="hidden bg-right lg:flex lg:w-3/12 xl:w-2/12 flex-col justify-between items-center text-white"
    >
      <div className="w-full py-10 px-10 2xl:px-20 flex flex-col gap-20">
        <div>
          <a href="/">
            <img src={logo} alt="" />
          </a>
          <p className="text-center font-light pt-2">ADMIN DASHBOARD</p>
        </div>
        <ul className="text-xl text-center flex flex-col gap-5">
          <li>
            <a
              href="/admin"
              className="hover-underline-animation hover:text-blue-300 cursor-pointer"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="/admin/users"
              className="hover-underline-animation hover:text-blue-300 cursor-pointer"
            >
              Users
            </a>
          </li>
          <li>
            <a
              href="/admin/instructors"
              className="hover-underline-animation hover:text-blue-300 cursor-pointer"
            >
              Instructors
            </a>
          </li>
          <li>
            <a
              href="/admin/courses"
              className="hover-underline-animation hover:text-blue-300 cursor-pointer"
            >
              Courses
            </a>
          </li>
          <li>
            <a
              href="/admin/categories"
              className="hover-underline-animation hover:text-blue-300 cursor-pointer"
            >
              Categories
            </a>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-8">
        <button
          onClick={async () => {
            await logoutUser();
            window.location.href = "/";
          }}
          className="rounded-md w-11/12 py-2 mx-auto bg-red-400 hover:bg-red-500"
        >
          Logout
        </button>
        <p className="text-gray-400 mb-3 text-sm text-center">
          Copyright &copy; K.M Ahnaf Zamil {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};
