import React, { useEffect, useState } from "react";
import { TContent } from "../../lib/types";

interface Props {
	contents: Array<TContent>;
}

export const OverviewContent: React.FC<Props> = ({ contents }) => {
	return (
		<div className="text-gray-800 w-full">
			<h1 className="text-2xl font-semibold mb-7">Course Content</h1>
			<div className="flex justify-center flex-col w-full gap-3">
				{contents.map((content, index) => (
					<div
						onClick={() =>
							(window.location.href += "/lessons/" + content.id)
						}
						className="border-l-4 hover:border-rose-400 transition duration-200 p-4 bg-gray-50 self-stretch hover:bg-gray-100 cursor-pointer w-full"
					>
						<i className="fas fa-play-circle pr-4"></i>{" "}
						{content.title}
					</div>
				))}
			</div>
		</div>
	);
};
