import React from "react";
import { useSelector } from "react-redux";
import { logoutUser } from "../../api/auth";
import logo from "../../assets/img/logo.png";
import { TCurrentUser } from "../../lib/types";
import { Avatar } from "../common/Avatar";
import { Spinner } from "../common/Spinner";

interface Props {
  courseId?: string;
}

export const CourseNavbar: React.FC<Props> = ({ courseId }) => {
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);
  return (
    <nav className="z-20 bg-slate-900 w-full text-white h-20 border-b border-white shadow-xl">
      <div className="w-9/12 container mx-auto flex items-center h-full justify-between">
        <h1 className="h-12 text-white text-3xl flex items-center">
          <i
            onClick={() => {
              if (courseId) {
                window.location.href = "/courses/enrolled/" + courseId;
              } else {
                window.location.href = "/dashboard";
              }
            }}
            className="cursor-pointer fas fa-chevron-left"
          ></i>
        </h1>
        <a href="/" className="relative">
          <img className="h-12 block" src={logo} alt="BITSHIFT" />
          <span className="absolute top-0 -right-10 text-rose-500 text-sm">
            Alpha
          </span>
        </a>
        <div className="h-10">
          {currentUser ? (
            <div className="dropdown relative  hidden sm:inline-block ">
              <p className="text-lg hover:underline transition duration-300 cursor-pointer flex items-center gap-3">
                <Avatar className="h-10" userID={currentUser.id} />
              </p>
              <ul
                style={{ left: "-60px" }}
                className="dropdown-menu absolute hidden text-gray-700 pt-2 w-44"
              >
                <li>
                  <a
                    className="rounded-t bg-white hover:bg-gray-100 py-3 px-4 block"
                    href="/dashboard"
                  >
                    <i className="fas fa-rocket pr-2"></i> ড্যাশবোর্ড
                  </a>
                </li>
                {currentUser.admin ? (
                  <li>
                    <a
                      className="bg-white hover:bg-gray-100 py-3 px-4 block"
                      href="/admin"
                    >
                      <i className="fas fa-users-cog pr-2"></i> অ্যাডমিন
                    </a>
                  </li>
                ) : null}
                <li>
                  <a
                    className="bg-white hover:bg-gray-100 py-3 px-4 block whitespace-no-wrap"
                    href="/profile"
                  >
                    <i className="fas fa-user pr-4"></i>
                    প্রোফাইল সেটিংস
                  </a>
                </li>
                <hr />
                <li>
                  <p
                    className="cursor-pointer rounded-b bg-white hover:bg-gray-100 py-3 px-4 block whitespace-no-wrap"
                    onClick={async () => {
                      await logoutUser();
                      window.location.href = "/";
                    }}
                  >
                    <i className="fas fa-sign-out-alt pr-2"></i> লগআউট
                  </p>
                </li>
              </ul>
            </div>
          ) : (
            <Spinner className="h-10" />
          )}
        </div>
      </div>
    </nav>
  );
};
