import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import { Router } from "./routers/Router";
import { store } from "./state/store";
import { Helmet } from "react-helmet";
import analytics from "./lib/analytics";
import ReactGA from "react-ga4";

const isDev = process.env.NODE_ENV === "development";

ReactGA.initialize(analytics.MEASUREMENT_ID, {
  testMode: isDev,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Helmet>
        <title>
          BitShift - Your Journey Into The World of Programming & IT
        </title>
        <meta
          name="description"
          content="Bitshift – A platform for all interested in Programming and IT. Master the fundamentals and elevate your skills affordably, all of that in Bangla."
        />
        <meta
          property="og:title"
          content="BitShift - Your Journey Into The World of IT"
        />
        <meta
          property="og:description"
          content="Bitshift – A platform for all interested in Programming and IT. Master the fundamentals and elevate your skills affordably, all of that in Bangla."
        />
      </Helmet>
      <Router />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
