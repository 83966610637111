const initialState = { tab: "content" };

export const courseOverviewReducer = (
  state = initialState,
  action: { type: string; data: object }
) => {
  switch (action.type) {
    case "SET_TAB":
      if (!state) {
        return { ...action.data };
      }
      return { ...(state as object), ...action.data };
    default:
      return state;
  }
};
