import React from "react";
import { TContent, TCourse } from "../../lib/types";
import { Thumbnail } from "../common/Thumbnail";

interface Props {
  course: TCourse;
  content: Array<TContent>;
  selectedLessonId: string;
}

export const LessonSidebar: React.FC<Props> = ({
  course,
  content,
  selectedLessonId,
}) => {
  return (
    <div className="hidden lg:block text-gray-800 h-full w-[500px] bg-white shadow-xl border-r-2 relative">
      <Thumbnail courseID={course.id} />
      <h1 className="text-center p-[25px] font-semibold text-[1.125rem]">
        {course.name}
      </h1>
      <div
        className="border-t overflow-y-auto"
        style={{ height: "calc(100vh - 80px - 220px - 77px)" }}
      >
        {content.map((lesson, index) => (
          <div
            onClick={() =>
              (window.location.href =
                "/courses/enrolled/" + course.id + "/lessons/" + lesson.id)
            }
            className={
              (selectedLessonId === lesson.id ? "border-rose-400 " : "") +
              "border-l-4 hover:border-rose-400 transition duration-200 p-4 hover:bg-gray-50 self-stretch hover:bg-gray-100 cursor-pointer w-full"
            }
          >
            <span className="font-light mr-2">{index + 1}. </span>
            {lesson.title}
          </div>
        ))}
      </div>
    </div>
  );
};
