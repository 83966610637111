import React from "react";
import { TInstructor } from "../../lib/types";
import { Avatar } from "../common/Avatar";

interface Props {
  instructor: TInstructor;
}

export const OverviewInstructor: React.FC<Props> = ({ instructor }) => {
  return (
    <div className="text-gray-800 h-full w-full">
      <h1 className="text-2xl font-semibold text-center">Course Instructor</h1>
      <div className="relative rounded-xl flex flex-col items-center my-6 p-8 gap-8 items-center">
        <Avatar
          className="h-48 shadow-lg"
          instructor={true}
          userID={instructor.id}
        />
        <div className="flex flex-col gap-3 text-center">
          <h1 className="font-light text-xl">{instructor.name}</h1>
          <p className="text-lg">{instructor.about}</p>
          <a href={`mailto:${instructor.email}`} className="text-blue-500">
            {instructor.email}
          </a>
          <p className="text-sm mt-2 text-gray-600">
            Joined BitShift on{" "}
            {new Date(parseInt(instructor.created_at)).toLocaleDateString(
              "en-AU"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
