import { httpClient } from "./http";
import config from "../config";

export const getPlatformStats = async () => {
  const resp = await httpClient.get(config.API_URL + "/admin/status");

  return resp.data;
};

export const getUsers = async (page: number = 1) => {
  const resp = await httpClient.get(
    config.API_URL + `/admin/users${page > 1 ? `?page=${page}` : ""}`
  );

  return resp.data;
};

// Instructors

export const getInstructors = async (page: number = 1) => {
  const resp = await httpClient.get(
    config.API_URL + `/admin/instructors${page > 1 ? `?page=${page}` : ""}`
  );

  return resp.data;
};

export const getInstructor = async (id: string) => {
  const resp = await httpClient.get(
    config.API_URL + `/admin/instructors/${id}`
  );

  return resp.data;
};

export const createInstructor = async (payload: any) => {
  const resp = await httpClient.post(
    config.API_URL + "/admin/instructors",
    payload
  );
  return resp.data;
};

export const updateInstructor = async (id: string, payload: any) => {
  const resp = await httpClient.patch(
    config.API_URL + `/admin/instructors/${id}`,
    payload
  );
  return resp.data;
};

export const deleteInstructor = async (id: string) => {
  await httpClient.delete(config.API_URL + `/admin/instructors/${id}`);
};

// Courses

export const getCourses = async (page: number = 1) => {
  const resp = await httpClient.get(
    config.API_URL + `/admin/courses${page > 1 ? `?page=${page}` : ""}`
  );

  return resp.data;
};

export const getCourse = async (id: string) => {
  const resp = await httpClient.get(config.API_URL + `/admin/courses/${id}`);

  return resp.data;
};

export const createCourse = async (payload: any) => {
  const resp = await httpClient.post(
    config.API_URL + "/admin/courses",
    payload
  );
  return resp.data;
};

export const updateCourse = async (id: string, payload: any) => {
  const resp = await httpClient.patch(
    config.API_URL + `/admin/courses/${id}`,
    payload
  );
  return resp.data;
};

export const deleteCourse = async (id: string) => {
  await httpClient.delete(config.API_URL + `/admin/courses/${id}`);
};

// Course contents

export const getContents = async (id: string) => {
  const resp = await httpClient.get(config.API_URL + `/admin/content/${id}`);
  return resp.data;
};

export const createContent = async (id: string, payload: object) => {
  const resp = await httpClient.post(
    config.API_URL + `/admin/content/${id}`,
    payload
  );
  return resp.data;
};

// Enrolments

export const getLatestEnrolments = async () => {
  const resp = await httpClient.get(
    config.API_URL + `/admin/enrolments/latest`
  );

  return resp.data.enrolments;
};

// Purchases

export const getLatestPurchases = async () => {
  const resp = await httpClient.get(config.API_URL + `/admin/purchases/latest`);

  return resp.data.purchases;
};

// Categories

export const getCategories = async (page: number = 1) => {
  const resp = await httpClient.get(
    config.API_URL + `/admin/categories${page > 1 ? `?page=${page}` : ""}`
  );
  console.log(resp.data);
  return resp.data;
};

export const createCategory = async (payload: any) => {
  const resp = await httpClient.post(
    config.API_URL + "/admin/categories",
    payload
  );
  return resp.data;
};
