import React, { useRef, useState } from "react";
import { AdminWrapper } from "../../Wrapper.admin";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { createContent } from "../../../api/admin";
import { useParams } from "react-router-dom";

export const AdminCreateContent: React.FC = () => {
	const { id } = useParams();
	const [message, setMessage] = useState<{ bg: string; msg: string } | null>(
		null
	);
	const loadingRef = useRef<LoadingBarRef>(null);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setMessage(null);
		const form = e.target as any;

		const payload: {
			title?: string;
			description?: string;
			video_url?: string;
		} = {};

		payload.title = form.title.value;
		payload.description = form.description.value;
		payload.video_url = form.video_url.value;

		try {
			loadingRef.current?.continuousStart(10, 15);
			await createContent(id as string, payload);
			loadingRef.current?.complete();
			window.location.href = "/admin/courses/" + id + "/content";
		} catch (e) {
			setMessage({ bg: "bg-red-500", msg: "There was an error" });
		} finally {
			loadingRef.current?.complete();
		}
	};

	return (
		<AdminWrapper title="Admin" name="Create a new content">
			<LoadingBar color="#f11946" ref={loadingRef} />
			<form onSubmit={handleSubmit} className="p-6 flex flex-col gap-6">
				{message && (
					<div
						className={`${message?.bg} rounded-md text-center py-4 text-lg text-white mb-8 container w-full`}
					>
						{message?.msg}
					</div>
				)}
				<div>
					<p className="font-semibold">Title</p>
					<input
						type="text"
						name="title"
						onChange={(e) => e.currentTarget.value}
						className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
						required
					/>
				</div>
				<div>
					<p className="font-semibold">Description</p>
					<textarea
						onChange={(e) => e.currentTarget.value}
						name="description"
						className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
						required
					></textarea>
				</div>
				<div>
					<p className="font-semibold">Video URL</p>
					<input
						type="text"
						name="video_url"
						onChange={(e) => e.currentTarget.value}
						className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
						required
					/>
				</div>
				<div>
					<button className="mt-6 w-60 px-4 py-2 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md">
						Submit Content
					</button>
				</div>
			</form>
		</AdminWrapper>
	);
};
