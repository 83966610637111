import React, { useEffect } from "react";
import { Navbar } from "../../../components/common/Navbar";
import { Wrapper } from "../../Wrapper";
import { Footer } from "../../../components/common/Footer";
import { Thumbnail } from "../../../components/common/Thumbnail";
import { useQueryParams } from "../../../lib/hooks";
import numberToText from "number-to-text";

export const BuyCourseFail: React.FC = () => {
  const args = useQueryParams();

  useEffect(() => {
    (async () => {
      if (!args.course_id) {
        window.location.href = "/";
      }
    })();
  }, [args.course_id]);

  return (
    <Wrapper title="Home">
      <Navbar />
      {args.course_id ? (
        <div className="container mx-auto lg:w-9/12 py-16 flex gap-6">
          <div className="bg-white px-12 py-16 h-full w-full flex items-center justify-center flex-col gap-8">
            <h1 className="text-9xl text-red-800">
              <i className="fas fa-exclamation-triangle"></i>
            </h1>
            <h1 className="text-center text-5xl font-semibold">
              আপনার পেমেন্ট ব্যর্থ হয়েছে
            </h1>
            <div className="text-xl flex flex-col gap-2">
              <h2>
                Reason: <span className="font-semibold">{args.error}</span>
              </h2>
              <h2>
                Bank Transaction ID:{" "}
                <span className="font-semibold">{args.trans_id}</span>
              </h2>
              <h2>
                Card Issuer:{" "}
                <span className="font-semibold">{args.card_issuer}</span>
              </h2>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Thumbnail
              className="w-full xl:w-[650px] rounded-md"
              courseID={args.course_id}
            />
            <p className="text-xl font-semibold">{args.course_name}</p>
            <p>
              Price:{" "}
              <span className="text-emerald-500 font-semibold">
                ৳ {args.course_price} (
                {numberToText.convertToText(args.course_price)} taka only)
              </span>
            </p>
          </div>
          {/* <div>
						<span className="text-3xl font-semibold text-gray-700">
							{args.course_name}
						</span>

						<p className="text-emerald-500 font-semibold text-xl mt-2">
							৳ ${args.course_price}
						</p>
					</div>
					<div className="grid grid-row-2  xl:grid-col-2 xl:grid-flow-col items-start gap-5 xl:gap-2 mt-6">
						<div className="flex flex-col gap-6">
							<Thumbnail
								className="w-full xl:w-[650px] rounded-md"
								courseID={args.course_id}
							/>
						</div>
						<div className="2xl:w-[700px] bg-white p-8 flex flex-col gap-3">
							<h1 className="text-2xl font-semibold text-gray-700 text-center">
								কোর্স সম্পর্কে
							</h1>
						</div>
					</div> */}
        </div>
      ) : null}
      <Footer />
    </Wrapper>
  );
};
