import React from "react";
import { TCurrentUser } from "../../lib/types";
import { Avatar } from "../common/Avatar";

interface Props {
	currentUser: TCurrentUser;
	title: string;
}

export const Topbar: React.FC<Props> = ({ currentUser, title }) => {
	return (
		<div className="p-8 shadow bg-white flex justify-between items-center w-full">
			<p className="text-light text-xl truncate">{title}</p>
			<p className="text-lg transition duration-300 flex items-center gap-3">
				<Avatar className="h-10" userID={currentUser.id} />
				<span className="hidden lg:block">{currentUser.name}</span>
			</p>
		</div>
	);
};
