import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEnrolledCourseData, getLesson } from "../../../api/course";
import { LessonSidebar } from "../../../components/course/LessonSidebar";
import { CourseNavbar } from "../../../components/course/Navbar";
import { TContent, TCourse } from "../../../lib/types";
import { NotFound } from "../../common/NotFound";
import { Wrapper } from "../../Wrapper";
import { getVideoId } from "../../../lib/utils";
import analytics from "../../../lib/analytics";

const pageTitle = "Watch Lesson";

export const WatchLesson: React.FC = () => {
  const { id, lessonid } = useParams();

  const [course, setCourse] = useState<TCourse | null>(null);
  const [content, setContent] = useState<Array<TContent> | null>(null);
  const [lesson, setLesson] = useState<TContent | null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);

  analytics.usePageHitEffect(pageTitle);

  useEffect(() => {
    (async () => {
      try {
        const courseData = await getEnrolledCourseData(id!);
        setCourse(courseData.course);
        setContent(courseData.content);

        const lessonData = await getLesson(id!, lessonid!);

        analytics.watchCourseLesson(courseData.course.name);

        setLesson(lessonData);
      } catch (e) {
        setNotFound(true);
      }
    })();
  }, [id, lessonid]);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <Wrapper title={pageTitle}>
      {course && content && lesson ? (
        <>
          <CourseNavbar courseId={course.id} />
          <div
            style={{ height: "calc(100vh - 80px)" }}
            className="flex lg:flex-row flex-col bg-white"
          >
            <>
              <LessonSidebar
                selectedLessonId={lessonid!}
                content={content}
                course={course}
              />
              <h1 className="block lg:hidden text-center p-[25px] font-semibold text-2xl">
                {course.name}
              </h1>
              <div className="px-2 lg:px-16 md:pt-8 pt-4 pb-8 lg:overflow-y-scroll w-full">
                <h1 className="text-2xl font-light mb-6">{lesson.title}</h1>
                <div
                  id="iframe-container"
                  className="w-full relative h-0 pb-[56.25%]"
                >
                  <iframe
                    className="w-full h-full absolute"
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${getVideoId(
                      lesson.video_url
                    )}?modestbranding=1`}
                    title={lesson.title}
                    allow="autoplay; clipboard-write; encrypted-media"
                    allowFullScreen
                  ></iframe>
                </div>
                <div>
                  <h1 className="text-xl font-light mt-8 mb-4">Description</h1>
                  <p>{lesson.description}</p>
                </div>
              </div>
              <div className="px-2 block lg:hidden">
                <h1 className="text-xl font-light mb-4">Course Content</h1>
                <div className="border-t">
                  {content.map((lesson, index) => (
                    <div
                      onClick={() =>
                        (window.location.href =
                          "/courses/enrolled/" +
                          course.id +
                          "/lessons/" +
                          lesson.id)
                      }
                      className={
                        (lessonid! === lesson.id ? "border-rose-400 " : "") +
                        "border-l-4 hover:border-rose-400 transition duration-200 p-4 hover:bg-gray-50 self-stretch hover:bg-gray-100 cursor-pointer w-full"
                      }
                    >
                      <span className="font-light mr-2">{index + 1}. </span>
                      {lesson.title}
                    </div>
                  ))}
                </div>
              </div>
            </>
          </div>
        </>
      ) : null}
    </Wrapper>
  );
};
