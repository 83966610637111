import React, { useRef } from "react";
import Lottie from "react-lottie";
import { motion } from "framer-motion";
import computerLottie from "../../assets/lotties/computer_home.json";
import { useLanguage } from "../../lib/hooks";

export const Hero: React.FC = () => {
  const constraintsRef = useRef(null);
  const langData = useLanguage();

  return (
    <div className="pt-10 w-9/12 container mx-auto h-[85vh] relative">
      <div className="w-full flex items-center justify-between h-full">
        <motion.div
          className="flex flex-col z-10 items-center lg:items-start"
          initial={{ x: -200 }}
          animate={{ x: 0 }}
        >
          <h1 className="text-5xl md:text-6xl font-semibold text-center lg:text-left">
            {langData.pages.home.heading}
          </h1>
          <button
            onClick={() => (window.location.href = "/dashboard")}
            className="w-64 text-xl mt-9 px-8 py-4 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md"
          >
            {langData.pages.home.heading_button}{" "}
            <i className="fas fa-arrow-right"></i>
          </button>
        </motion.div>
        <div className="w-full h-full absolute top-0 opacity-20 lg:static lg:opacity-100 flex items-center">
          <motion.div ref={constraintsRef}>
            <motion.div drag dragConstraints={constraintsRef}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: computerLottie,
                }}
                isClickToPauseDisabled={true}
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
      <p className="text-xl cursor-pointer w-full text-center">
        <i
          onClick={() =>
            document.getElementById("introduction")?.scrollIntoView()
          }
          className="absolute bottom-12 fas fa-chevron-down"
        ></i>
      </p>
    </div>
  );
};
