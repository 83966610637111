import React from "react";
import spinnerSVG from "../../assets/svg/spinner.svg";

interface Props {
	className?: string;
}

export const Spinner: React.FC<Props> = ({ className }) => {
	return (
		<img src={spinnerSVG} alt="" className={className ? className : ""} />
	);
};
