import React, { useEffect, useState } from "react";
import { getLatestCourses } from "../../api/course";
import { TCourse } from "../../lib/types";
import { CourseCard } from "../common/Course";
import { Spinner } from "../common/Spinner";
import { useLanguage } from "../../lib/hooks";

export const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Array<TCourse> | null>(null);
  const langData = useLanguage();

  useEffect(() => {
    (async () => {
      const data = await getLatestCourses();
      setCourses(data);
    })();
  }, []);

  return (
    <section id="newcourses" className="bg-gray-50 pb-20">
      <div className="container mx-auto h-full text-gray-900">
        <h1 className="text-4xl font-bold text-gray-700 text-center pt-16 pb-16">
          {langData.pages.home.new_course_header}
        </h1>
        <div className="text-gray-600 px-2 flex flex-wrap justify-center mx-auto items-center  gap-5">
          {courses ? (
            courses?.map((course) => <CourseCard course={course} />)
          ) : (
            <div className="flex justify-center items-center h-48">
              <Spinner className="w-20 h-full" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
