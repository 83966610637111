import { httpClient } from "./http";
import config from "../config";

export const getLatestCourses = async () => {
  const resp = await httpClient.get(config.API_URL + "/course/latest");
  return resp.data.courses;
};

export const getCourse = async (id: string) => {
  const resp = await httpClient.get(config.API_URL + `/course/${id}`);
  return resp.data.course;
};

export const enrolCourse = async (id: string) => {
  const resp = await httpClient.post(config.API_URL + `/course/enrol/${id}`);
  return resp.data;
};

export const getCourses = async () => {
  const resp = await httpClient.get(config.API_URL + "/course/all");
  return resp.data.courses;
};

export const getMyCourses = async () => {
  const resp = await httpClient.get(config.API_URL + "/course/@me");
  return resp.data.courses;
};

export const getEnrolledCourseData = async (id: string) => {
  const resp = await httpClient.get(config.API_URL + `/course/enrolled/${id}`);
  return resp.data;
};

export const getCourseContentList = async (id: string) => {
  const resp = await httpClient.get(config.API_URL + `/course/content/${id}`);
  return resp.data;
};

export const getLesson = async (courseId: string, lessonId: string) => {
  const resp = await httpClient.get(
    config.API_URL + `/course/enrolled/${courseId}/${lessonId}`
  );
  return resp.data.lesson;
};
