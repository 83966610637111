import React, { useEffect, useState } from "react";
import { getLatestPurchases } from "../../api/admin";
import { Spinner } from "../common/Spinner";

interface Purchase {
	course_id: string;
	course_name: string;
	user_id: string;
	user_name: string;
	purchase_id: string;
	received_amount: number;
	created_at: string;
}

export const LatestPurchases: React.FC = () => {
	const [purchases, setPurchases] = useState<Array<Purchase> | null>(null);

	useEffect(() => {
		(async () => {
			setPurchases(await getLatestPurchases());
		})();
	}, []);

	return (
		<div className="shadow-xl bg-white w-full">
			<h1 className="text-center py-4 text-xl">Latest Purchases</h1>
			{purchases ? (
				<table className="w-full table-auto max-h-[1065px]">
					<thead>
						<tr className="bg-white h-10 text-center">
							<th>#</th>
							<th>Name</th>
							<th>Course</th>
							<th>Amount Received</th>
							<th>Purchased At</th>
						</tr>
					</thead>
					<tbody>
						{purchases.map((purchase, index) => (
							<tr className="h-10 border-y">
								<td className="border-r w-12 text-center">
									{index + 1}
								</td>
								<td className="border-r px-4 text-blue-500">
									<a
										href={`/admin/users/${purchase.user_id}`}
									>
										{purchase.user_name}
									</a>
								</td>
								<td className="border-r px-4 text-blue-500">
									<a
										href={`/admin/courses/${purchase.course_id}`}
									>
										{purchase.course_name}
									</a>
								</td>
								<td className="border-r px-4 text-blue-500">
									<a
										href={`/admin/users/${purchase.purchase_id}`}
									>
										৳ {purchase.received_amount}
									</a>
								</td>
								<td className="border-r px-4">
									{new Date(
										parseInt(purchase.created_at)
									).toLocaleDateString("en-AU")}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className="flex justify-center items-center h-40">
					<Spinner className="w-20 h-full" />
				</div>
			)}
		</div>
	);
};
