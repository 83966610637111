import React from "react";
import { Navbar } from "../../components/common/Navbar";
import { Wrapper } from "../Wrapper";
import { Footer } from "../../components/common/Footer";

export const NotFound: React.FC = () => {
  return (
    <Wrapper title="Not Found" noAuth={true}>
      <Navbar />
      <div className="h-[calc(100vh-421px)] flex items-center justify-center">
        <h1 className="text-center text-gray-700 text-5xl font-light">
          404: Page Not Found
        </h1>
      </div>
      <Footer />
    </Wrapper>
  );
};
