import React from "react";
import { Routes, Route } from "react-router-dom";
import { AdminCreateCourse } from "../pages/admin/courses/CreateCourse";
import { AdminCourses } from "../pages/admin/courses/ShowCourses";
import { AdminViewCourse } from "../pages/admin/courses/ViewCourse";
import { AdminHome } from "../pages/admin/Home";
import { AdminCreateInstructor } from "../pages/admin/instructors/CreateInstructor";
import { AdminInstructors } from "../pages/admin/instructors/ShowInstructors";
import { AdminViewInstructor } from "../pages/admin/instructors/ViewInstructor";
import { AdminUsers } from "../pages/admin/common/Users";
import { AdminCategories } from "../pages/admin/common/Categories";
import { AdminShowContents } from "../pages/admin/content/ShowContents";
import { AdminCreateContent } from "../pages/admin/content/CreateContent";

export const AdminRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<AdminHome />} />
			<Route path="/users" element={<AdminUsers />} />

			<Route path="/instructors" element={<AdminInstructors />} />
			<Route
				path="/instructors/create"
				element={<AdminCreateInstructor />}
			/>
			<Route path="/instructors/:id" element={<AdminViewInstructor />} />

			<Route path="/courses" element={<AdminCourses />} />
			<Route path="/courses/create" element={<AdminCreateCourse />} />
			<Route path="/courses/:id" element={<AdminViewCourse />} />
			<Route
				path="/courses/:id/content"
				element={<AdminShowContents />}
			/>
			<Route
				path="/courses/:id/content/create"
				element={<AdminCreateContent />}
			/>

			<Route path="/categories" element={<AdminCategories />} />
		</Routes>
	);
};
