import React from "react";
import logo from "../../assets/img/logo.png";
import config from "../../config";

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = ({ className }) => {
  return (
    <footer
      className={
        "bg-slate-900 w-full text-white border-b border-white shadow-xl " +
        (className || "")
      }
    >
      <div className="w-9/12 py-16 container mx-auto flex flex-col md:grid grid-cols-3 gap-5 justify-center">
        <a href="/">
          <img className="h-16 block" src={logo} alt="" />
        </a>
        <div className="text-lg flex flex-col gap-2 text-blue-300">
          <h1 className="font-semibold text-xl text-white">BitShift</h1>
          <a href="/">Home</a>
          <a href="/courses">Courses</a>
          <a href="/about">About us</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
        <div className="text-lg flex flex-col gap-2">
          <h1 className="font-semibold text-lg">
            For any inquiries, technical issues, or support
            <br />
            Contact Us
          </h1>
          <p>
            Email:{" "}
            <a
              className="text-blue-300"
              href={"mailto:" + config.SUPPORT_EMAIL}
            >
              {config.SUPPORT_EMAIL}
            </a>
          </p>
        </div>
      </div>
      <h1 className="text-center pb-4">
        Copyright &copy; K.M Ahnaf Zamil {new Date().getFullYear()} | All Rights
        Reserved
      </h1>
    </footer>
  );
};
