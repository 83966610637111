import { TCurrentUser } from "../../lib/types";

const initialState: TCurrentUser | null = null;

export const userReducer = (
	state = initialState,
	action: { type: string; data: object }
) => {
	switch (action.type) {
		case "SET_USER":
			if (!state) {
				return { ...action.data };
			}
			return { ...(state as object), ...action.data };
		default:
			return state;
	}
};
