import { useEffect } from "react";
import ReactGA from "react-ga4";

const analytics = {
  MEASUREMENT_ID: "G-9VJ740EY29",
  usePageHitEffect: (title: string) => {
    useEffect(() => {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: title,
      });
    }, []);
  },
  loginUser: (userMail: string) => {
    ReactGA.event({
      category: "Users",
      action: "Logged In",
      label: userMail,
    });
  },
  enrolCourse: (courseName: string) => {
    ReactGA.event({
      category: "Courses",
      action: "Enrolled In Course",
      label: courseName,
    });
  },
  viewEnrolledCourse: (courseName: string) => {
    // Triggered whenever user visits the overview page of an enrolled course
    ReactGA.event({
      category: "Courses",
      action: "Viewed Enrolled Course",
      label: courseName,
      nonInteraction: true,
    });
  },
  watchCourseLesson: (courseName: string) => {
    // Triggered whenever user watches a lesson of a course
    ReactGA.event({
      category: "Courses",
      action: "Watched Course Content",
      label: courseName,
    });
  },
};

export default analytics;
