import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMyCourses } from "../../api/course";
import { CourseCard } from "../../components/common/Course";
import { Footer } from "../../components/common/Footer";
import { Navbar } from "../../components/common/Navbar";
import { Spinner } from "../../components/common/Spinner";
import { TCourse, TCurrentUser } from "../../lib/types";
import { Wrapper } from "../Wrapper";
import analytics from "../../lib/analytics";

const pageTitle = "Dashboard";

export const Dashboard: React.FC = () => {
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);
  const [courses, setCourses] = useState<Array<TCourse> | null>(null);

  analytics.usePageHitEffect(pageTitle);

  useEffect(() => {
    (async () => {
      if (currentUser) {
        if (currentUser.unauthorized) {
          window.location.href = "/login?redirect=/dashboard";
        }

        const data = await getMyCourses();
        setCourses(data);
      }
    })();
  }, [currentUser]);

  return (
    <Wrapper title={pageTitle}>
      <Navbar />
      <div className="container mx-auto xl:w-9/12">
        <h1 className="text-center text-4xl my-16">My Courses</h1>
        <div className="mb-16 text-gray-600 flex flex-wrap justify-center mx-auto items-center gap-16">
          {courses ? (
            <>
              {!courses.length ? (
                <h1 className="text-4xl font-light text-gray-700 text-center pt-16 pb-20">
                  আপনি কোনো কোর্সে ভর্তি হননি
                </h1>
              ) : null}
              {courses?.map((course) => (
                <CourseCard
                  price={false}
                  course={course}
                  href={`/courses/enrolled/${course.id}`}
                />
              ))}
            </>
          ) : (
            <div className="flex justify-center items-center h-48 w-full">
              <Spinner className="w-20 h-full" />
            </div>
          )}
        </div>
      </div>
      <Footer className={!courses?.length ? "fixed bottom-0" : ""} />
    </Wrapper>
  );
};
