import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../api/auth";
import { TCurrentUser } from "../lib/types";

interface Props {
  children: React.ReactNode;
  title: string;
  noAuth?: boolean;
}

export const Wrapper: React.FC<Props> = ({ children, title, noAuth }) => {
  const dispatch = useDispatch();
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);

  useEffect(() => {
    if (currentUser || noAuth) {
      return;
    }
    getLoggedInUser().then((user) => {
      if (user) {
        dispatch({ type: "SET_USER", data: user });
      } else {
        dispatch({ type: "SET_USER", data: { unauthorized: true } });
      }
    });
  }, []);

  return <>{children}</>;
};
