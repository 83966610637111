import React from "react";
import { Navbar } from "../../components/common/Navbar";
import { Wrapper } from "../Wrapper";
import { Hero } from "../../components/home/Hero";
import { Introduction } from "../../components/home/Introduction";
import { Courses } from "../../components/home/Courses";
import backgroundSVG from "../../assets/svg/blurry-gradient.svg";
import { Footer } from "../../components/common/Footer";
import { WhyBitShift } from "../../components/home/WhyBitShift";
import analytics from "../../lib/analytics";
import { YouTubeLatest } from "../../components/home/YouTubeLatest";
import { FAQ } from "../../components/home/FAQ";

const pageTitle = "Home";

export const Home: React.FC = () => {
  analytics.usePageHitEffect(pageTitle);

  return (
    <Wrapper title={pageTitle}>
      <div
        style={{ backgroundImage: `url(${backgroundSVG})` }}
        className="text-white"
      >
        <Navbar />
        <Hero />
      </div>
      <Introduction />
      <WhyBitShift />
      <Courses />
      <YouTubeLatest />
      <FAQ />
      <Footer />
    </Wrapper>
  );
};
