import React from "react";
import { useSelector } from "react-redux";
import { logoutUser } from "../../api/auth";
import logo from "../../assets/img/logo.png";
import { TCurrentUser } from "../../lib/types";
import { Avatar } from "./Avatar";
import { useLanguage } from "../../lib/hooks";

export const Navbar: React.FC = () => {
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);
  const langData = useLanguage();

  const handleLangChange = () => {
    let newLang = langData.localStorageName === "bangla" ? "english" : "bangla";
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };

  return (
    <>
      <nav className="z-20 bg-slate-900 fixed w-full text-white py-4 border-b border-white shadow-xl">
        {/* PC Navbar */}
        <div className="hidden lg:px-16 xl:w-10/12 container mx-auto md:flex items-center h-full justify-between">
          <a href="/" className="relative">
            <img className="h-12 block" src={logo} alt="BITSHIFT" />
            <span className="absolute top-0 -right-10 text-rose-500 text-sm">
              Alpha
            </span>
          </a>
          <ul className="flex lg:gap-5 text-lg lg:text-xl items-center text-center">
            <li className="hover-underline-animation cursor-pointer px-2 py-2 rounded-md">
              <a href="/courses">
                {langData.components.navbar.buttons.courses}
              </a>
            </li>
            <li className="hover-underline-animation cursor-pointer px-2 py-2 rounded-md">
              <a href="/about">{langData.components.navbar.buttons.about}</a>
            </li>
            <li className="hover-underline-animation cursor-pointer px-2 py-2 rounded-md">
              <a href="/dashboard">
                {langData.components.navbar.buttons.dashboard}
              </a>
            </li>
          </ul>

          <div
            className="
		  flex items-center gap-8"
          >
            {currentUser?.id ? (
              <div className="dropdown inline-block relative">
                <p className="text-lg hover:underline transition duration-300 cursor-pointer flex items-center gap-3">
                  <Avatar className="h-10" userID={currentUser.id} />
                  <span className="hidden lg:block">{currentUser.name}</span>
                </p>
                <ul className="dropdown-menu absolute hidden text-gray-700 pt-2 w-44">
                  <li>
                    <a
                      className="rounded-t bg-white hover:bg-gray-100 py-3 px-4 block"
                      href="/dashboard"
                    >
                      <i className="fas fa-rocket pr-2"></i>
                      {langData.components.navbar.user_dropdown.dashboard}
                    </a>
                  </li>
                  {currentUser.admin ? (
                    <li>
                      <a
                        className="bg-white hover:bg-gray-100 py-3 px-4 block"
                        href="/admin"
                      >
                        <i className="fas fa-users-cog pr-2"></i> Admin
                      </a>
                    </li>
                  ) : null}
                  <li>
                    <a
                      className="bg-white hover:bg-gray-100 py-3 px-4 block whitespace-no-wrap"
                      href="/profile"
                    >
                      <i className="fas fa-user pr-4"></i>

                      {langData.components.navbar.user_dropdown.profile}
                    </a>
                  </li>
                  <hr />
                  <li>
                    <a
                      className="cursor-pointer rounded-b bg-white hover:bg-gray-100 py-3 px-4 block whitespace-no-wrap"
                      onClick={async () => {
                        await logoutUser();
                        window.location.href = "/";
                      }}
                    >
                      <i className="fas fa-sign-out-alt pr-2"></i>
                      {langData.components.navbar.user_dropdown.logout}
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <a href="/login">
                <button className="bg-blue-400 hover:bg-blue-500 px-4 py-2 rounded-lg">
                  {langData.components.navbar.buttons.login}
                </button>
              </a>
            )}

            <button
              onClick={handleLangChange}
              className=" border border-white rounded px-2 py-1 flex items-center gap-2"
            >
              <img
                className="h-6"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADaklEQVR4nO2ZS0hVURSGT2ZpmQ3MSshoXoOIiqRxhlkQRea8JtUkaJJBkBVEj6El6NhJkAVFTYIGQg+LQI1w4qSMHvZw0tMeXyxb15bb89j33qNe4f6zc/a/197/OWfv/a91gqCIIoqYANAA9AI/yB7S5zHQqLEqgNfAGPAJGAC6gRagDigJ0gSwHfhD/pAYOzTmsRjeMHAeWJWWgAekh16Nuc6DOwZcAZblK+CzCboyh/5Vpv93c38FcFInGocPQFM+AuwAC3KMMYGQtt3AL0O5DvSHCJG3UVpwArT9tLMGKoC9wCtHxG2gvBAFlAFDhnZI71frpC3uZPUmZkKAcg4a2qC5Pw+46H5OhShA3sJ7Q93ktMsasNhfUAKU126oF5y2EuCasztVF5qAbYbaF9Iua+Kd4bRN+zmQpYBy4KtSf4c9Yfl0TLixxBMbuI8/vgAdQGWSANLDuSQB9Tl4IdkSt8yQgOFEA6iG7qFYgSwC/1SrMH+aBQjqgjQANAOjTEaPvfCIIQ8rg7sxvC7Da/GZXLXPCQiscSedpYD1ht4fwztqeN0+Ap6aDpsTuCU6wJQEyGOcGkN/E8PbangDPgJemA6RB4gmK+OLShay43G+BikDKAVW+xBli8xgYQxPrPE9oFavK4FO4CPQGswWmPw5LI7hye6DTtgrvwV2at6cd84dCZ1QBrWeJ3ekUMPfRXpojBvoWZRTdHgjhpdotoAnaefcodDSRwaHY3jPDW9tFM/w7WdTlcT3zbnDiCcMsSuGJws4g/rIgP/5E0ji5hVDt8TUkNXgKQmQw+nlnBUgEOs61wXUAN8MvzliUUkygrrXpakMnlYM4LLhj0RkTX2G01RoAqqc6kG3e+I6ZZCughIgkKfqfNLtgYG4VdMmJfQy256mAHzPgZCObp3mkhShTDFq0LQdyFYA/0xgh2Micz+JI2zsrZCy33JtP2LuD0W9hTABTLXhPpC8vcFbgA60CLjpBJKC7D5giVOcPZMkQPNnyaMzjtYHYkUeZT15503YnSkD+XV0w1zL1ronQYCbio6GbdXTAi04WScaBnmyp2yBLIbbI/n1jEze2RHaPMsv42XKuHJMMFuQch9wNsE7jf+oMKXEKQWxWYcawI3AceCqrou3WtPcoJxWrTR3uiXJIoooIsgZfwFacPlZlHF7owAAAABJRU5ErkJggg=="
              />
              {langData.prev}
            </button>
          </div>
        </div>
        {/* Mobile navbar */}
        <div className="md:hidden w-11/12 container mx-auto flex items-center h-full justify-between">
          <div className="dropdown inline-block relative">
            <span className="text-white text-3xl cursor-pointer font-semibold py-2 px-4 rounded inline-flex items-center">
              ☰
            </span>
            <ul className="dropdown-menu absolute hidden text-white pt-1 w-36">
              <li>
                <a
                  className="rounded-t bg-slate-900 hover:bg-slate-800 py-2 px-4 block"
                  href="/courses"
                >
                  {langData.components.navbar.buttons.courses}
                </a>
              </li>
              <li>
                <a
                  className="bg-slate-900 hover:bg-slate-800 py-2 px-4 block whitespace-no-wrap"
                  href="/about"
                >
                  {langData.components.navbar.buttons.about}
                </a>
              </li>
              <li>
                <a
                  className="bg-slate-900 hover:bg-slate-800 py-2 px-4 block whitespace-no-wrap"
                  href="/dashboard"
                >
                  {langData.components.navbar.buttons.dashboard}
                </a>
              </li>
              <li className=" h-6 w-full">
                <a
                  onClick={handleLangChange}
                  className="cursor-pointer flex border-t border-white items-center gap-2 rounded-b bg-slate-900 hover:bg-slate-800 py-2 px-4 block whitespace-no-wrap"
                >
                  <img
                    className="h-6"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADaklEQVR4nO2ZS0hVURSGT2ZpmQ3MSshoXoOIiqRxhlkQRea8JtUkaJJBkBVEj6El6NhJkAVFTYIGQg+LQI1w4qSMHvZw0tMeXyxb15bb89j33qNe4f6zc/a/197/OWfv/a91gqCIIoqYANAA9AI/yB7S5zHQqLEqgNfAGPAJGAC6gRagDigJ0gSwHfhD/pAYOzTmsRjeMHAeWJWWgAekh16Nuc6DOwZcAZblK+CzCboyh/5Vpv93c38FcFInGocPQFM+AuwAC3KMMYGQtt3AL0O5DvSHCJG3UVpwArT9tLMGKoC9wCtHxG2gvBAFlAFDhnZI71frpC3uZPUmZkKAcg4a2qC5Pw+46H5OhShA3sJ7Q93ktMsasNhfUAKU126oF5y2EuCasztVF5qAbYbaF9Iua+Kd4bRN+zmQpYBy4KtSf4c9Yfl0TLixxBMbuI8/vgAdQGWSANLDuSQB9Tl4IdkSt8yQgOFEA6iG7qFYgSwC/1SrMH+aBQjqgjQANAOjTEaPvfCIIQ8rg7sxvC7Da/GZXLXPCQiscSedpYD1ht4fwztqeN0+Ap6aDpsTuCU6wJQEyGOcGkN/E8PbangDPgJemA6RB4gmK+OLShay43G+BikDKAVW+xBli8xgYQxPrPE9oFavK4FO4CPQGswWmPw5LI7hye6DTtgrvwV2at6cd84dCZ1QBrWeJ3ekUMPfRXpojBvoWZRTdHgjhpdotoAnaefcodDSRwaHY3jPDW9tFM/w7WdTlcT3zbnDiCcMsSuGJws4g/rIgP/5E0ji5hVDt8TUkNXgKQmQw+nlnBUgEOs61wXUAN8MvzliUUkygrrXpakMnlYM4LLhj0RkTX2G01RoAqqc6kG3e+I6ZZCughIgkKfqfNLtgYG4VdMmJfQy256mAHzPgZCObp3mkhShTDFq0LQdyFYA/0xgh2Micz+JI2zsrZCy33JtP2LuD0W9hTABTLXhPpC8vcFbgA60CLjpBJKC7D5giVOcPZMkQPNnyaMzjtYHYkUeZT15503YnSkD+XV0w1zL1ronQYCbio6GbdXTAi04WScaBnmyp2yBLIbbI/n1jEze2RHaPMsv42XKuHJMMFuQch9wNsE7jf+oMKXEKQWxWYcawI3AceCqrou3WtPcoJxWrTR3uiXJIoooIsgZfwFacPlZlHF7owAAAABJRU5ErkJggg=="
                  />
                  {langData.prev}
                </a>
              </li>
            </ul>
          </div>
          <a href="/" className="relative">
            <img className="h-12 block" src={logo} alt="BITSHIFT" />
            <span className="absolute top-0 -right-10 text-rose-500 text-sm">
              Alpha
            </span>
          </a>

          {currentUser?.id ? (
            <div className="dropdown inline-block relative">
              <p className="text-lg hover:underline transition duration-300 cursor-pointer">
                <Avatar className="h-10" userID={currentUser.id} />
              </p>
              <ul className="dropdown-menu absolute -left-28 lg:left-0 hidden text-gray-700 pt-2 w-44">
                <li>
                  <a
                    className="rounded-t bg-white hover:bg-gray-100 py-3 px-4 block"
                    href="/dashboard"
                  >
                    <i className="fas fa-rocket pr-2"></i>
                    {langData.components.navbar.user_dropdown.dashboard}
                  </a>
                </li>
                <li>
                  <a
                    className="bg-white hover:bg-gray-100 py-3 px-4 block whitespace-no-wrap"
                    href="/profile"
                  >
                    <i className="fas fa-user pr-2"></i>
                    {langData.components.navbar.user_dropdown.profile}
                  </a>
                </li>
                <hr />
                <li>
                  <a
                    className="cursor-pointer rounded-b bg-white hover:bg-gray-100 py-3 px-4 block whitespace-no-wrap"
                    onClick={async () => {
                      await logoutUser();
                      window.location.href = "/";
                    }}
                  >
                    <i className="fas fa-sign-out-alt pr-2"></i>
                    {langData.components.navbar.user_dropdown.logout}
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <a href="/login">
              <button className="bg-blue-400 hover:bg-blue-500 px-4 py-2 rounded-lg">
                {langData.components.navbar.buttons.login}
              </button>
            </a>
          )}
        </div>
      </nav>
      <div className="py-10"></div>
    </>
  );
};
