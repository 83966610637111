import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "../pages/auth/Login";
import { Home } from "../pages/common/Home";
import { Profile } from "../pages/auth/Profile";
import { AdminRouter } from "./Router.admin";
import { ShowCourse } from "../pages/course/view/ShowCourse";
import { BuyCourse } from "../pages/course/buy/BuyCourse";
import { BuyCourseFail } from "../pages/course/buy/BuyCourseFail";
import { NotFound } from "../pages/common/NotFound";
import { PrivacyPolicy } from "../pages/common/PrivacyPolicy";
import { ViewCourses } from "../pages/course/view/ViewCourses";
import { Dashboard } from "../pages/auth/Dashboard";
import { CourseOverview } from "../pages/course/watch/CourseOverview";
import { WatchLesson } from "../pages/course/watch/WatchLesson";
import { AboutUs } from "../pages/common/AboutUs";
// import { TestPage } from "../pages/Test";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/admin/*" element={<AdminRouter />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />

        <Route path="/courses" element={<ViewCourses />} />
        <Route path="/courses/enrolled/:id" element={<CourseOverview />} />
        <Route
          path="/courses/enrolled/:id/lessons/:lessonid"
          element={<WatchLesson />}
        />
        <Route path="/courses/:id" element={<ShowCourse />} />
        <Route path="/courses/checkout" element={<BuyCourse />} />
        <Route path="/courses/checkout/failed" element={<BuyCourseFail />} />
        {/* <Route path="/test" element={<TestPage />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
