export const newlineText = (text: string) => {
  return text
    .split("\n")
    .map((str) => <p className="pt-2">{str.replace("-", "✓")}</p>);
};

export const getVideoId = (url: string) => {
  const regex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  return regex.exec(url)![3];
};
