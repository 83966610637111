import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../../../components/common/Navbar";
import { Wrapper } from "../../Wrapper";
import { Footer } from "../../../components/common/Footer";
import { TCourse, TCurrentUser } from "../../../lib/types";
import { useLocation } from "react-router-dom";
import { Thumbnail } from "../../../components/common/Thumbnail";
import { useSelector } from "react-redux";
import numberToText from "number-to-text";
import "number-to-text/converters/en-us";
import { getCheckoutURL } from "../../../api/checkout";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";

export const BuyCourse: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const location = useLocation();
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);
  const [course, setCourse] = useState<TCourse | null>(null);
  const loadingRef = useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (!location.state) {
      window.location.href = "/dashboard";
      return;
    }

    const state = (location.state as any).course;

    if (!currentUser) {
      window.location.href = "/";
      return;
    }

    setCourse(state);
  }, []);

  const handleCheckout = async () => {
    if (!course) {
      return;
    }

    loadingRef.current?.continuousStart(10, 15);
    const resp = await getCheckoutURL(course.id);
    loadingRef.current?.complete();

    if (resp.status === "SUCCESS") {
      window.location.href = resp.gateway_url;
      return;
    } else {
      setMessage("An error has occured");
    }
  };

  return (
    <Wrapper title="Home">
      <Navbar />
      <LoadingBar color="#f11946" ref={loadingRef} />
      {course && currentUser ? (
        <div className="container mx-auto lg:w-9/12 py-20">
          {message && (
            <div
              className={`bg-red-500 rounded-md text-center py-4 text-lg text-white mb-8 px-6 container`}
            >
              {message}
            </div>
          )}
          <div className="items-start gap-5 w-full bg-white p-4 rounded-md">
            <div className="flex gap-6 h-full">
              <div className="w-full xl:w-[650px]">
                <Thumbnail className="rounded-l-md" courseID={course.id} />
              </div>
              <div className="flex flex-col gap-4 justify-between">
                <div className="flex flex-col gap-4">
                  <span className="text-xl">
                    আপনি এই কোর্সটি কিনতে যাচ্ছেন:
                  </span>
                </div>
                <div>
                  <p className="text-2xl font-semibold text-gray-700">
                    {course.name}
                  </p>

                  <p>
                    <span className="text-xl mr-2">Price:</span>
                    <span className="text-emerald-500 font-semibold text-xl mt-2">
                      {course.price < 1
                        ? "Free"
                        : `৳ ${course.price} (${numberToText.convertToText(
                            course.price
                          )} taka only)`}
                    </span>
                  </p>
                </div>
                <div>
                  <p>Your User ID: {currentUser.id}</p>
                  <small>
                    Important*: Keep note of your user ID, so that we can
                    recognize you if you need support after payment failure
                  </small>
                  <button
                    onClick={handleCheckout}
                    className="w-full h-auto mt-4 py-3 bg-emerald-500 hover:bg-emerald-600 text-white text-xl rounded"
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </Wrapper>
  );
};
