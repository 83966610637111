import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../api/auth";
import { Sidebar } from "../components/admin/Sidebar";
import { Topbar } from "../components/admin/TopBar";
import { TCurrentUser } from "../lib/types";
import { NotFound } from "./common/NotFound";

interface Props {
	children: React.ReactNode;
	title: string;
	name: string;
}

export const AdminWrapper: React.FC<Props> = ({ children, title, name }) => {
	const dispatch = useDispatch();
	const currentUser: TCurrentUser = useSelector(
		(state) => (state as any).user
	);

	useEffect(() => {
		if (currentUser) {
			return;
		}
		getLoggedInUser().then((user) => {
			if (user) {
				if (user.admin) {
					dispatch({ type: "SET_USER", data: user });
				}
			}
		});
	}, []);

	if (currentUser) {
		return currentUser.admin ? (
			<>
				<Helmet>
					<title>{title} - BitShift</title>
				</Helmet>
				<div className="overflow-y-hidden h-screen w-full flex bg-gray-100">
					<Sidebar />
					<div className="  w-full lg:w-9/12 xl:w-10/12 text-gray-800">
						<Topbar title={name} currentUser={currentUser} />
						<div className="p-6 flex flex-col overflow-y-scroll h-[calc(100vh-104px)]">
							{children}
						</div>
					</div>
				</div>
			</>
		) : (
			<NotFound />
		);
	}
	return <NotFound />;
};
