import React, { useEffect, useState } from "react";
import { getLatestEnrolments } from "../../api/admin";
import { Spinner } from "../../components/common/Spinner";

interface Enrolment {
	course_id: string;
	course_name: string;
	user_id: string;
	user_name: string;
	enrolled_at: string;
}

export const LatestEnrolments: React.FC = () => {
	const [enrolments, setEnrolments] = useState<Array<Enrolment> | null>(null);

	useEffect(() => {
		(async () => {
			setEnrolments(await getLatestEnrolments());
		})();
	}, []);

	return (
		<div className="shadow-xl bg-white w-full">
			<h1 className="text-center py-4 text-xl">Latest Enrolments</h1>
			{enrolments ? (
				<table className="w-full table-auto max-h-[1065px]">
					<thead>
						<tr className="bg-white text-left h-10">
							<th>#</th>
							<th>Name</th>
							<th>Course</th>
							<th>Enrolled At</th>
						</tr>
					</thead>
					<tbody>
						{enrolments.map((enrolment, index) => (
							<tr className="h-10 border-y">
								<td className="border-r w-12 text-center">
									{index + 1}
								</td>
								<td className="border-r px-4 text-blue-500">
									<a
										href={`/admin/users/${enrolment.user_id}`}
									>
										{enrolment.user_name}
									</a>
								</td>
								<td className="border-r px-4 text-blue-500">
									<a
										href={`/admin/courses/${enrolment.course_id}`}
									>
										{enrolment.course_name}
									</a>
								</td>
								<td className="border-r px-4">
									{new Date(
										parseInt(enrolment.enrolled_at)
									).toLocaleDateString("en-AU")}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className="flex justify-center items-center h-40">
					<Spinner className="w-20 h-full" />
				</div>
			)}
		</div>
	);
};
