import React from "react";
import { useLanguage } from "../../lib/hooks";

export const Introduction: React.FC = () => {
  const langData = useLanguage();
  return (
    <section className="bg-white text-black pb-20" id="introduction">
      <div className="pt-20">&nbsp;</div>
      <div className="h-full">
        <h1 className="text-4xl font-bold text-gray-700 text-center container mx-auto">
          {langData.pages.home.intro_header}
        </h1>
        <p className="text-center p-6 text-gray-600 text-lg">
          {langData.pages.home.intro_subheader}
        </p>
        <div className="flex flex-wrap justify-center lg:w-7/12 mx-auto items-center text-white gap-5">
          <div className="shadow-lg cursor-pointer hover:bg-red-500 transition-all duration-300 transform hover:scale-[1.05] bg-red-400 px-8 py-6 text-xl rounded-2xl">
            <i className="fas fa-terminal"></i> &nbsp;
            {langData.pages.home.intro_cards.prog_lang}
          </div>
          <div className="shadow-lg cursor-pointer hover:bg-teal-500 transition-all duration-300 transform hover:scale-[1.05] bg-teal-400 px-8 py-6 text-xl rounded-2xl">
            <i className="fas fa-sitemap"></i> &nbsp;
            {langData.pages.home.intro_cards.sys_design}
          </div>
          <div className="shadow-lg cursor-pointer hover:bg-indigo-500 transition-all duration-300 transform hover:scale-[1.05] bg-indigo-400 px-8 py-6 text-xl rounded-2xl">
            <i className="fas fa-database"></i> &nbsp;
            {langData.pages.home.intro_cards.dbms}
          </div>
          <div className="shadow-lg cursor-pointer hover:bg-sky-500 transition-all duration-300 transform hover:scale-[1.05] bg-sky-400 px-8 py-6 text-xl rounded-2xl">
            <i className="fas fa-laptop-code"></i> &nbsp;
            {langData.pages.home.intro_cards.web}
          </div>
          <div className="shadow-lg cursor-pointer hover:bg-amber-500 transition-all duration-300 transform hover:scale-[1.05] bg-amber-400 px-8 py-6 text-xl rounded-2xl">
            <i className="fas fa-shield-alt"></i> &nbsp;
            {langData.pages.home.intro_cards.app_sec}
          </div>
        </div>
      </div>
    </section>
  );
};
