import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCourses } from "../../../api/admin";
import { Spinner } from "../../../components/common/Spinner";
import { TAdminCourse } from "../../../lib/types";
import { AdminWrapper } from "../../Wrapper.admin";

export const AdminCourses: React.FC = () => {
  const [courses, setCourses] = useState<Array<TAdminCourse> | null>(null);
  const [limitHit, setLimitHit] = useState<boolean>(false);
  const search = useLocation().search;
  let page = new URLSearchParams(search).get("page");

  if (page === "1") {
    page = null;
  }

  useEffect(() => {
    (async () => {
      const limit = 15;
      let resp;
      if (page) {
        resp = await getCourses(parseInt(page));
      } else {
        resp = await getCourses();
      }
      if (resp.courses?.length < limit) {
        setLimitHit(true);
      }
      setCourses(resp.courses);
    })();
  }, [page]);

  return (
    <AdminWrapper title="Admin" name="Courses">
      <button
        onClick={() => (window.location.href = "/admin/courses/create")}
        className="absolute bottom-0 right-0 m-6 text-white text-2xl text-center rounded-full h-14 w-14 hover:bg-sky-800 bg-sky-700"
      >
        +
      </button>
      {courses ? (
        <>
          <table className="w-full table-auto shadow max-h-[1065px]">
            <thead>
              <tr className="bg-white text-left h-10">
                <th>#</th>
                <th>ID</th>
                <th>Name</th>
                <th>Price</th>
                <th>Active</th>
                <th>Created At</th>
                <th>Content</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr className="bg-slate-50 h-10 even:bg-slate-300 ">
                  <td>{index + 1}</td>
                  <td>
                    <motion.span
                      initial={{ x: 0 }}
                      whileTap={{ x: 15 }}
                      className="block cursor-pointer text-blue-500 hover:text-blue-600"
                      onClick={() => navigator.clipboard.writeText(course.id)}
                    >
                      Copy ID
                    </motion.span>
                  </td>
                  <td className="cursor-pointer text-blue-500 hover:text-blue-600">
                    <a href={`/admin/courses/${course.id}`}>{course.name}</a>
                  </td>
                  <td>{course.price === 0 ? "Free" : `৳ ${course.price}`}</td>
                  <td>{course.active ? "Yes" : "No"}</td>
                  <td>
                    {new Date(parseInt(course.created_at)).toLocaleString(
                      "en-AU"
                    )}
                  </td>
                  <td className="cursor-pointer text-blue-500 hover:text-blue-600">
                    <a href={`/admin/courses/${course.id}/content`}>
                      Click to view
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="container flex justify-center mx-auto mt-8">
            <ul className="flex bg-white text-slate-700">
              {page ? (
                <>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/courses?page=${
                          parseInt(page as any) - 1
                        }`)
                      }
                      className="h-10 px-5  border border-r-0 border-slate-600 hover:bg-slate-100"
                    >
                      Prev
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/courses?page=${
                          parseInt(page as any) - 1
                        }`)
                      }
                      className="h-10 px-5 border border-r-0 border-slate-600 hover:bg-slate-100"
                    >
                      {parseInt(page) - 1}
                    </button>
                  </li>
                </>
              ) : null}
              <li>
                <button
                  className={
                    "h-10 px-5 border border-r-0 border-slate-600 " +
                    `${
                      page
                        ? `${
                            parseInt(page) > 1 ? "bg-slate-600 text-white" : ""
                          }`
                        : "bg-slate-600 text-white"
                    }`
                  }
                >
                  {page ? page : 1}
                </button>
              </li>
              {!limitHit ? (
                <>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/courses?page=${
                          page ? parseInt(page as any) + 1 : 2
                        }`)
                      }
                      className={
                        "h-10 px-5 border border-r-0 border-slate-600 "
                      }
                    >
                      {page ? parseInt(page) + 1 : 2}
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/courses?page=${
                          page ? parseInt(page as any) + 1 : 2
                        }`)
                      }
                      className="h-10 px-5 border border-slate-600 hover:bg-slate-100"
                    >
                      Next
                    </button>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <Spinner className="w-20 h-full" />
        </div>
      )}
    </AdminWrapper>
  );
};
