import React from "react";
import { TCourse } from "../../lib/types";
import { Thumbnail } from "./Thumbnail";
import { useLanguage } from "../../lib/hooks";

interface Props {
  course: TCourse;
  price?: boolean;
  href?: string | null;
}

export const CourseCard: React.FC<Props> = ({
  course,
  price = true,
  href = null,
}) => {
  const langData = useLanguage();

  return (
    <a
      href={href ? href : `/courses/${course.id}`}
      target="_blank"
      rel="noopener noreferrer"
      className="group transform hover:scale-[1.03] duration-300 block lg:h-[420px] w-96 bg-white rounded-lg transition duration-250 hover:shadow-2xl cursor-pointer"
    >
      <div className="relative">
        <div className="text-white flex items-center justify-center w-full h-full absolute top-0 left-0 duration-300 bg-gray-700 opacity-0 bg-opacity-40 group-hover:opacity-100 rounded-t-lg">
          <h1 className="text-xl p-4 border-2 duration-300 hover:bg-white hover:text-black border-white">
            {langData.components.course_card.text}
          </h1>
        </div>
        <Thumbnail className="rounded-t-lg" courseID={course.id} />
      </div>
      <div className="p-5">
        <h1 className="text-center text-gray-800 font-semibold text-lg max-h-[56px] line-clamp-2">
          {course.name}
        </h1>
        <p className="text-center pt-3 line-clamp-2">{course.description}</p>
        {price && (
          <h3 className="pt-5 text-center text-emerald-700 text-lg font-semibold">
            {course.price > 0 ? `৳ ${course.price}` : "Free"}
          </h3>
        )}
      </div>
    </a>
  );
};
