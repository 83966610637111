import { httpClient } from "./http";
import config from "../config";
import { TCurrentUser } from "../lib/types";

export const logInGoogleUser = async (
  tokenType: string,
  accessToken: string
) => {
  const resp = await httpClient.post(config.API_URL + "/auth/callback/google", {
    token_type: tokenType,
    access_token: accessToken,
  });

  return resp.data;
};

export const getLoggedInUser = async () => {
  try {
    const resp = await httpClient.get(config.API_URL + "/auth/@me");
    return resp.data as TCurrentUser;
  } catch (e) {
    return null;
  }
};

export const logoutUser = async () => {
  await httpClient.post(config.API_URL + "/auth/logout");
  return true;
};

export const updateSettings = async (payload: any) => {
  const resp = await httpClient.post(config.API_URL + "/user/update", payload);
  return resp.data;
};
