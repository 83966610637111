import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../../../components/common/Navbar";
import { Wrapper } from "../../Wrapper";
import { Footer } from "../../../components/common/Footer";
import { TCourse, TCurrentUser } from "../../../lib/types";
import {
  enrolCourse,
  getCourse,
  getCourseContentList,
} from "../../../api/course";
import { useNavigate, useParams } from "react-router-dom";
import { Thumbnail } from "../../../components/common/Thumbnail";
import { getVideoId, newlineText } from "../../../lib/utils";
import { Avatar } from "../../../components/common/Avatar";
import { useSelector } from "react-redux";
import { Spinner } from "../../../components/common/Spinner";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet";
import config from "../../../config";
import analytics from "../../../lib/analytics";

const pageTitle = "View Course";

export const ShowCourse: React.FC = () => {
  const { id } = useParams();
  const [course, setCourse] = useState<TCourse | null>(null);
  const [courseContent, setCourseContent] = useState<Array<string> | null>(
    null
  );
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);
  const navigate = useNavigate();
  const loadingRef = useRef<LoadingBarRef>(null);

  analytics.usePageHitEffect(pageTitle);

  useEffect(() => {
    async function fetchCourseData() {
      const data = await getCourse(id as string);
      setCourse(data);
    }

    async function fetchCourseContent() {
      const data = await getCourseContentList(id as string);
      setCourseContent(data);
    }

    fetchCourseData();
    fetchCourseContent();
  }, [id]);

  const handleBtnClick = async () => {
    if (!course) {
      return;
    }

    if (course.enrolled) {
      window.location.href = `/courses/enrolled/${course.id}`;
      return;
    }

    if (course.price < 1) {
      // If course is free, directly enrol user
      if (currentUser.unauthorized) {
        window.location.href = "/login?redirect=/courses/" + course.id;
      }
      loadingRef.current?.continuousStart(10, 15);
      const resp = await enrolCourse(course.id);

      // Send google analytic event for course enrolment
      analytics.enrolCourse(course.name);

      loadingRef.current?.complete();
      if (resp.success) {
        window.location.reload();
      }
    } else {
      // If not, redirect user to checkout
      navigate("/courses/checkout", {
        state: { course: course },
      });
    }
  };

  return (
    <Wrapper title={pageTitle}>
      <Navbar />
      <LoadingBar color="#f11946" ref={loadingRef} />

      {course ? (
        <>
          <Helmet>
            <title>{course.name} - BitShift</title>
            <meta name="description" content={course.description} />
            <meta property="og:title" content={course.name + " - BitShift"} />
            <meta property="og:description" content={course.description} />
            <meta
              property="og:image"
              content={`${config.CDN_URL}/courses/${course.id}.png`}
            />
          </Helmet>
          <div className="container mx-auto lg:w-10/12 py-16 px-5 xl:px-0">
            <div className="flex xl:flex-row flex-col justify-center gap-6 w-full">
              <div className="flex flex-col">
                <div className="mb-6">
                  <span className="text-3xl font-semibold text-gray-700">
                    {course.name}
                  </span>
                </div>
                <Carousel
                  className="w-full xl:w-[550px] 2xl:w-[650px] rounded-md block"
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={5000}
                  swipeable={true}
                  thumbWidth={120}
                >
                  <Thumbnail courseID={course.id} />
                  {course.trailer_url === "" ? (
                    <></>
                  ) : (
                    <div className="w-full h-full">
                      <object
                        data={`https://www.youtube.com/embed/${getVideoId(
                          course.trailer_url!
                        )}?modestbranding=1`}
                        width="100%"
                        height="100%"
                      >
                        Video Embed
                      </object>
                    </div>
                  )}
                </Carousel>
                <div className="w-full xl:w-[550px] 2xl:w-[650px] bg-white py-8 px-4 flex flex-col gap-3">
                  <h1 className="text-2xl font-semibold text-gray-700 text-center">
                    কোর্স কন্টেন্ট
                  </h1>
                  <div className="w-full gap-3  overflow-y-scroll max-h-[300px]">
                    {courseContent &&
                      courseContent.map((content, index) => (
                        <div
                          className={`transition duration-200 p-4 bg-${
                            index % 2 === 1 ? "white" : "gray-100"
                          } self-stretch w-full`}
                        >
                          {index + 1}. {content}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="w-full 2xl:w-[700px] xl:mt-0 flex flex-col gap-6">
                <div className="w-full bg-white p-8 flex flex-col gap-3">
                  <h1 className="text-2xl font-semibold text-gray-700 text-center">
                    কোর্স সম্পর্কে
                  </h1>
                  <p>{newlineText(course.description)}</p>
                  <button
                    onClick={handleBtnClick}
                    className="w-full h-auto mt-4 py-3 bg-teal-500 hover:bg-teal-600 text-white text-xl rounded"
                  >
                    {course.enrolled
                      ? "Go to course"
                      : course.price < 1
                      ? "Enroll for free"
                      : `৳ ${course.price}`}
                  </button>
                </div>

                <div className="bg-white p-8 w-full rounded-md">
                  <h1 className="text-2xl font-semibold text-gray-700 mb-4">
                    প্রশিক্ষক
                  </h1>
                  <div className="flex items-center gap-6">
                    <Avatar
                      userID={course.instructor?.id!}
                      className="h-24 rounded-xl"
                      instructor={true}
                    />
                    <div>
                      <h1 className="font-semibold">
                        {course.instructor?.name}
                      </h1>
                      <p>{course.instructor?.about}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-[70vh]">
          <Spinner className="w-20 h-full" />
        </div>
      )}
      <Footer />
    </Wrapper>
  );
};
