import React from "react";
import { useLanguage } from "../../lib/hooks";

const WhyComponent: React.FC<{ title: string; desc: string }> = ({
  title,
  desc,
}) => {
  return (
    <div className="bg-white cursor-default hover:bg-gray-50  text-center transition-all border hover:border-green-500 shadow-lg rounded py-10 px-16">
      <h1 className="font-medium text-xl mb-3">{title}</h1>
      <p>{desc}</p>
    </div>
  );
};

export const WhyBitShift: React.FC = () => {
  const langData = useLanguage();
  return (
    <section id="why-bitshift" className="bg-gray-50 pb-20">
      <div className="container mx-auto h-full text-gray-900 flex flex-col items-center">
        <h1 className="text-4xl font-bold text-gray-700 text-center pt-16 pb-20">
          {langData.pages.home.why_header}
        </h1>
        <div className="mx-4 text-gray-600 px-2 mx-auto gap-12 grid md:grid-cols-2 2xl:grid-cols-4 xl:w-[1000px] 2xl:w-[1600px]">
          {langData.pages.home.why_cards.map((x) => (
            <WhyComponent title={x.head} desc={x.sub} />
          ))}
        </div>
      </div>
    </section>
  );
};
