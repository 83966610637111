import React from "react";
import config from "../../config";

interface Props {
  userID: string;
  className?: string;
  instructor?: boolean;
}

export const Avatar: React.FC<Props> = ({ userID, className, instructor }) => {
  const url = `${config.CDN_URL}/${
    instructor ? "instructors" : "avatars"
  }/${userID}.png`;

  return (
    <img
      src={url}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = `${config.CDN_URL}/avatars/default.png`;
      }}
      alt=""
      className={"rounded-full " + (className ? className : "")}
    />
  );
};
