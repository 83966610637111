import React, { createRef, useEffect, useRef, useState } from "react";
import { Navbar } from "../../components/common/Navbar";
import { Wrapper } from "../Wrapper";
import { Footer } from "../../components/common/Footer";
import { useSelector } from "react-redux";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { TCurrentUser } from "../../lib/types";
import { Avatar } from "../../components/common/Avatar";
import imageCompression from "browser-image-compression";
import { logoutUser, updateSettings } from "../../api/auth";
import analytics from "../../lib/analytics";

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";

const pageTitle = "Profile Settings";

export const Profile: React.FC = () => {
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);

  const [image, setImage] = useState<string | null>(null);
  const cropperRef = createRef<ReactCropperElement>();

  const [message, setMessage] = useState<{ bg: string; msg: string } | null>(
    null
  );
  const loadingRef = useRef<LoadingBarRef>(null);

  analytics.usePageHitEffect(pageTitle);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.unauthorized) {
        window.location.href = "/login?redirect=/profile";
      }
    }
  }, [currentUser]);

  const handleAvatarSelect = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setMessage(null);

    if (target.files && target.files[0]) {
      let files = target.files;
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(null);
    const form = e.target as any;

    const payload: {
      name?: string;
      avatar?: string;
    } = {};

    if (typeof cropperRef.current?.cropper === "undefined") {
      return;
    }

    if (form.avatar.files.length > 0) {
      if (form.avatar.files[0]["type"].split("/")[0] !== "image") {
        return setMessage({
          bg: "bg-red-500",
          msg: "Invalid image selected",
        });
      }
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(
        await imageCompression.getFilefromDataUrl(
          cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
          "avatar"
        ),
        options
      );
      const imageDataURI = await imageCompression.getDataUrlFromFile(
        compressedImage
      );
      payload.avatar = imageDataURI;
    }

    if (form.name.value !== currentUser.name) {
      payload.name = form.name.value;
    }

    if (Object.keys(payload).length === 0) {
      return setMessage({
        bg: "bg-red-500",
        msg: "You did not change anything",
      });
    }

    try {
      loadingRef.current?.continuousStart(10, 15);
      await updateSettings(payload);
      loadingRef.current?.complete();
      window.location.reload();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setMessage({
          bg: "bg-red-500",
          msg: "Error: " + e.response!.data.detail,
        });
      }
    } finally {
      loadingRef.current?.complete();
    }
  };

  return (
    <Wrapper title={pageTitle}>
      <Navbar />
      <LoadingBar color="#f11946" ref={loadingRef} />
      <div className="bg-gray-100 flex items-center justify-center flex-col  mt-16">
        {message && (
          <div
            className={`${message?.bg} rounded-md text-center py-4 text-lg text-white mb-8 px-6 container md:w-8/12 lg:w-6/12 xl:w-5/12`}
          >
            {message?.msg}
          </div>
        )}
        <h1 className="text-4xl font-semibold text-center">
          আপনার প্রোফাইল সেটিংস
        </h1>
        <div className="bg-white shadow-lg rounded-lg mt-8 container md:w-8/12 lg:w-6/12 xl:w-5/12 mb-16">
          <form onSubmit={handleSubmit} className="p-6 flex flex-col gap-6">
            <div>
              <p className="font-semibold">Full Name</p>
              <input
                type="text"
                name="name"
                defaultValue={currentUser?.name}
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 mt-2 w-full border rounded-md"
              />
            </div>
            <div>
              <p className="font-semibold">Profile Image</p>
              <div className="flex items-center gap-4">
                {currentUser ? (
                  <Avatar
                    userID={currentUser?.id}
                    className="w-20 mt-3 border border-gray-700 rounded-full"
                  />
                ) : null}
                <div className="bg-gray-50">
                  <input
                    type="file"
                    name="avatar"
                    accept="image/png, image/jpeg"
                    onChange={handleAvatarSelect}
                    id="avatarInp"
                    className="block w-full
                                            px-3
                                            py-1.5
                                            text-base
                                            font-normal
                                            text-gray-700
                                            bg-gray-50 bg-clip-padding
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  />
                </div>
              </div>
              {image && (
                <>
                  <Cropper
                    className="mt-8"
                    ref={cropperRef}
                    style={{ width: "100%", height: "400px" }}
                    initialAspectRatio={1}
                    aspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={3}
                    dragMode="move"
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    guides={true}
                    movable={true}
                    zoomOnWheel={false}
                  />
                  <p className="font-semibold mt-10 mb-4">Preview</p>
                  <div
                    className="img-preview"
                    style={{ height: 200, width: 200, overflow: "hidden" }}
                  />
                </>
              )}
            </div>
            <button className="w-60 text-lg px-4 py-2 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md">
              প্রোফাইল আপডেট করুন
            </button>
          </form>
          <hr />
          <div className="p-6 w-full">
            <button
              onClick={async () => {
                await logoutUser();
                window.location.href = "/";
              }}
              className="w-full text-lg px-4 py-2 border border-rose-500 transition-all duration-300 text-white bg-rose-500 hover:bg-rose-600 rounded-md"
            >
              এই একাউন্ট থেকে লগআউট করুন
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};
