import React from "react";
import config from "../../config";
interface Props {
  courseID: string;
  className?: string;
}

export const Thumbnail: React.FC<Props> = ({ courseID, className }) => {
  const url = `${config.CDN_URL}/courses/${courseID}.png`;

  return <img src={url} alt="" className={className ? className : ""} />;
};
