import React from "react";
import { Footer } from "../../components/common/Footer";
import { Navbar } from "../../components/common/Navbar";
import { Wrapper } from "../Wrapper";
import analytics from "../../lib/analytics";

const pageTitle = "About Us";

export const AboutUs: React.FC = () => {
  analytics.usePageHitEffect(pageTitle);

  return (
    <Wrapper title={pageTitle}>
      <Navbar />
      <div className="w-9/12 text-lg leading-8 container mx-auto my-8">
        <h1 className="text-3xl font-medium mb-8 text-center">
          About Bitshift
        </h1>
        Welcome to BitShift - Your Promising Journey into the World of
        Programming and Tech!
        <br />
        <h2 className="font-semibold text-xl mt-8 mb-4">Our Story</h2>
        <p>
          BitShift is the brainchild of the recognition of challenges faced by
          Bangladeshis when it comes to learning programming and IT topics. In
          an era where technology is the driving force behind global progress,
          the lack of accessible and affordable resources is a significant
          roadblock for many.
          <br />
          <br />
          We understand that finding high-quality tutorials and courses on
          diverse tech topics can be a daunting task. Often, the options are
          limited, expensive, or not available in the native language. BitShift
          was born out of the need to bridge these gaps and provide a viable
          solution.
        </p>
        <h2 className="font-semibold text-xl mt-8 mb-4">Our Mission</h2>
        <p>
          At BitShift, our mission is clear: to make IT and programming
          education accessible to all, regardless of financial constraints or
          language barriers. We aspire to offer a wide range of courses at
          affordable prices, and in many cases, for free.
          <br />
          <br />
          While we may be new in the tech education landscape, our pricing model
          is designed to cater to the needs of Bangladeshis who might find it
          challenging to afford expensive courses on platforms like Udemy and
          others. We are determined to grow and develop into a platform that
          provides even greater opportunities as we gain experience.
        </p>
        <h2 className="font-semibold text-xl mt-8 mb-4">
          Learning in Your Native Language
        </h2>
        <p>
          Language should never be a barrier to learning. BitShift is committed
          to providing courses and resources in Bangla, ensuring that you can
          grasp complex technical concepts with ease. We understand that
          mastering programming languages and IT skills is challenging enough
          without the added challenge of learning in English, as it is not the
          mother tongue of Bangladeshis.
        </p>
      </div>
      <Footer />
    </Wrapper>
  );
};
