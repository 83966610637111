import React, { useEffect, useRef, useState } from "react";
import { AdminWrapper } from "../../Wrapper.admin";
import imageCompression from "browser-image-compression";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import {
  deleteInstructor,
  getInstructor,
  updateInstructor,
} from "../../../api/admin";
import { TInstructor } from "../../../lib/types";
import { useParams } from "react-router-dom";
import { Avatar } from "../../../components/common/Avatar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Spinner } from "../../../components/common/Spinner";
import { AxiosError } from "axios";

export const AdminViewInstructor: React.FC = () => {
  const { id } = useParams();
  const [instructor, setInstructor] = useState<TInstructor | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [message, setMessage] = useState<{ bg: string; msg: string } | null>(
    null
  );
  const loadingRef = useRef<LoadingBarRef>(null);

  useEffect(() => {
    (async () => {
      setInstructor(await getInstructor(id as string));
    })();
  }, []);

  const handleImageSelect = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setMessage(null);

    if (target.files && target.files[0]) {
      if (target.files[0]["type"].split("/")[0] !== "image") {
        e.preventDefault();

        return setMessage({
          bg: "bg-red-500",
          msg: "Invalid image selected",
        });
      }

      setSelectedImage(URL.createObjectURL(target.files[0]));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!instructor) {
      return;
    }

    e.preventDefault();
    setMessage(null);
    const form = e.target as any;

    const payload: {
      name?: string;
      avatar?: string;
      about?: number;
      email?: string;
    } = {};

    payload.name = form.name.value;
    payload.about = form.about.value;
    payload.email = form.email.value;

    if (form.avatar.files.length > 0) {
      if (form.avatar.files[0]["type"].split("/")[0] !== "image") {
        return setMessage({
          bg: "bg-red-500",
          msg: "Invalid image selected",
        });
      }
      const options = {
        maxWidthOrHeight: 1280,
        maxSizeMB: 1,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(
        form.avatar.files[0],
        options
      );
      const imageDataURI = await imageCompression.getDataUrlFromFile(
        compressedImage
      );
      payload.avatar = imageDataURI;
    }

    try {
      loadingRef.current?.continuousStart(10, 15);
      await updateInstructor(instructor.id, payload);
      loadingRef.current?.complete();
      window.location.reload();
    } catch (e) {
      setMessage({
        bg: "bg-red-500",
        msg: "There was an error: " + (e as AxiosError).response?.data.detail,
      });
    } finally {
      loadingRef.current?.complete();
    }
  };

  return (
    <AdminWrapper
      title="Admin"
      name={`Editing instructor: ${instructor ? instructor.name : ""}`}
    >
      <LoadingBar color="#f11946" ref={loadingRef} />
      {instructor ? (
        <form onSubmit={handleSubmit} className="p-6 flex flex-col gap-6">
          {message && (
            <div
              className={`${message?.bg} rounded-md text-center py-4 text-lg text-white mb-8 container w-full`}
            >
              {message?.msg}
            </div>
          )}
          <div>
            <p className="font-semibold">Name</p>
            <input
              type="text"
              name="name"
              defaultValue={instructor.name}
              onChange={(e) => e.currentTarget.value}
              className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
              required
            />
          </div>
          <div>
            <p className="font-semibold">About</p>
            <input
              type="text"
              name="about"
              defaultValue={instructor.about ? instructor.about : ""}
              onChange={(e) => e.currentTarget.value}
              className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
              required
            />
          </div>
          <div>
            <p className="font-semibold">Email</p>
            <input
              type="email"
              name="email"
              defaultValue={instructor.email}
              onChange={(e) => e.currentTarget.value}
              className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
              required
            />
          </div>
          <div>
            <p className="font-semibold">Profile Image</p>
            <div className="flex items-center gap-4">
              <div className="bg-gray-50">
                <input
                  type="file"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  onChange={handleImageSelect}
                  id="avatarInp"
                  className="block w-full
                                            px-3
                                            py-1.5
                                            text-base
                                            font-normal
                                            text-gray-700
                                            bg-gray-50 bg-clip-padding
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>
              {selectedImage ? (
                <>
                  <img
                    src={selectedImage}
                    className="h-[200px] mt-3 border border-gray-700"
                    alt=""
                  />
                </>
              ) : (
                <Avatar
                  className="h-[200px] mt-3 border border-gray-700"
                  userID={instructor.id}
                  instructor={true}
                />
              )}
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="mt-6 w-60 text-lg px-4 py-2 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md"
              >
                Update Instructor
              </button>
              <button
                type="button"
                onClick={() => {
                  confirmAlert({
                    title: "Are you sure you want to delete this instructor?",
                    message: (instructor as any).name,
                    buttons: [
                      {
                        label: "Yes",
                        onClick: async () => {
                          await deleteInstructor((instructor as any).id);
                          window.location.href = "/admin/instructors";
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {},
                      },
                    ],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                  });
                }}
                className="mt-6 w-60 text-lg px-4 py-2 border border-red-400 transition-all duration-300 text-red-400 hover:bg-red-500 hover:text-white rounded-md"
              >
                Delete Instructor
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="flex justify-center items-center h-full">
          <Spinner className="w-20 h-full" />
        </div>
      )}
    </AdminWrapper>
  );
};
