import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { createCategory, getCategories } from "../../../api/admin";
import { Spinner } from "../../../components/common/Spinner";
import { AdminWrapper } from "../../Wrapper.admin";

export const AdminCategories: React.FC = () => {
  const [categories, setCategories] = useState<Array<{
    id: string;
    name: string;
  }> | null>(null);
  const [message, setMessage] = useState<{ bg: string; msg: string } | null>(
    null
  );
  const loadingRef = useRef<LoadingBarRef>(null);
  const [limitHit, setLimitHit] = useState<boolean>(false);
  const search = useLocation().search;
  let page = new URLSearchParams(search).get("page");

  if (page === "1") {
    page = null;
  }

  useEffect(() => {
    (async () => {
      const limit = 15;
      let resp;
      if (page) {
        resp = await getCategories(parseInt(page));
      } else {
        resp = await getCategories();
      }
      if (resp.categories.length < limit) {
        setLimitHit(true);
      }
      setCategories(resp.categories);
    })();
  }, [page]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as any;

    try {
      loadingRef.current?.continuousStart(10, 15);
      await createCategory({ name: form.name.value });
      loadingRef.current?.complete();
      window.location.href = "/admin/courses";
    } catch (e) {
      setMessage({ bg: "bg-red-500", msg: "There was an error" });
    } finally {
      loadingRef.current?.complete();
    }
  };

  return (
    <AdminWrapper title="Admin" name="Categories">
      <LoadingBar color="#f11946" ref={loadingRef} />
      {message && (
        <div
          className={`${message?.bg} rounded-md text-center py-4 text-lg text-white mb-8 container w-full`}
        >
          {message?.msg}
        </div>
      )}
      {categories ? (
        <>
          <div className="my-4">
            <h1 className="font-semibold mb-2">Create a Category</h1>
            <form onSubmit={handleSubmit} className="flex gap-4">
              <input
                type="text"
                name="name"
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 w-full border-gray-400 border rounded-md"
                required
              />
              <button
                type="submit"
                className="w-60 px-4 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md"
              >
                Submit
              </button>
            </form>
          </div>
          <table className="w-full table-auto shadow max-h-[1065px]">
            <thead>
              <tr className="bg-white text-left h-10">
                <th>#</th>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((user, index) => (
                <tr className="bg-slate-50 h-10 even:bg-slate-300 ">
                  <td>{index + 1}</td>
                  <td>
                    <motion.span
                      initial={{ x: 0 }}
                      whileTap={{ x: 15 }}
                      className="block cursor-pointer text-blue-500 hover:text-blue-600"
                      onClick={() => navigator.clipboard.writeText(user.id)}
                    >
                      Copy ID
                    </motion.span>
                  </td>
                  <td>{user.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="container flex justify-center mx-auto mt-8">
            <ul className="flex bg-white text-slate-700">
              {page ? (
                <>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/categories?page=${
                          parseInt(page as any) - 1
                        }`)
                      }
                      className="h-10 px-5  border border-r-0 border-slate-600 hover:bg-slate-100"
                    >
                      Prev
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/categories?page=${
                          parseInt(page as any) - 1
                        }`)
                      }
                      className="h-10 px-5 border border-r-0 border-slate-600 hover:bg-slate-100"
                    >
                      {parseInt(page) - 1}
                    </button>
                  </li>
                </>
              ) : null}
              <li>
                <button
                  className={
                    "h-10 px-5 border border-r-0 border-slate-600 " +
                    `${
                      page
                        ? `${
                            parseInt(page) > 1 ? "bg-slate-600 text-white" : ""
                          }`
                        : "bg-slate-600 text-white"
                    }`
                  }
                >
                  {page ? page : 1}
                </button>
              </li>
              {!limitHit ? (
                <>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/categories?page=${
                          page ? parseInt(page as any) + 1 : 2
                        }`)
                      }
                      className={
                        "h-10 px-5 border border-r-0 border-slate-600 "
                      }
                    >
                      {page ? parseInt(page) + 1 : 2}
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        (window.location.href = `/admin/categories?page=${
                          page ? parseInt(page as any) + 1 : 2
                        }`)
                      }
                      className="h-10 px-5 border border-slate-600 hover:bg-slate-100"
                    >
                      Next
                    </button>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <Spinner className="w-20 h-full" />
        </div>
      )}
    </AdminWrapper>
  );
};
