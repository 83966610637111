import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../../components/common/Navbar";
import { Wrapper } from "../Wrapper";
import { Footer } from "../../components/common/Footer";
import { GoogleLogin } from "react-google-login";
import authConfig from "../../auth.config";
import { logInGoogleUser } from "../../api/auth";
import { useSelector } from "react-redux";
import { TCurrentUser } from "../../lib/types";
import { useQueryParams } from "../../lib/hooks";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import analytics from "../../lib/analytics";

const pageTitle = "Login";

export const Login: React.FC = () => {
  const currentUser: TCurrentUser = useSelector((state) => (state as any).user);
  const params = useQueryParams();
  const [error, setError] = useState<string | null>();
  const loadingRef = useRef<LoadingBarRef>(null);

  analytics.usePageHitEffect(pageTitle);

  const handleGoogleSuccess = async (response: any) => {
    setError(null);
    const { token_type, access_token } = response.tokenObj; // token_type exists, but for some reason it's not on type interface
    loadingRef.current?.continuousStart(10, 15);
    const resp = await logInGoogleUser(token_type, access_token);

    // Send user login analytic to GA
    analytics.loginUser(resp.email);

    loadingRef.current?.complete();
    window.location.href = params.redirect ? params.redirect : "/dashboard";
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.id) {
        window.location.href = "/dashboard";
      }
    }
  }, [currentUser]);

  return (
    <Wrapper title={pageTitle}>
      <Navbar />
      <LoadingBar color="#f11946" ref={loadingRef} />
      <div className="pt-20 h-[80vh] bg-gray-100 flex items-center justify-center flex-col">
        {error && (
          <div className="text-center bg-red-400 border border-red-500 text-white rounded-md text-xl px-4 py-2 mb-10">
            {error}
          </div>
        )}
        <h1 className="font-semibold text-4xl text-center mb-5 text-gray-700">
          লগইন করুন
        </h1>
        <GoogleLogin
          clientId={authConfig.GOOGLE.CLIENT_ID}
          buttonText="Log In With Google"
          onSuccess={handleGoogleSuccess}
          onFailure={(err) => {
            setError(`দুঃখিত, লগ ইন করার সময় একটি ত্রুটি ঘটেছে: ${err.error}`);
          }}
          cookiePolicy={"single_host_origin"}
        />
      </div>
      <Footer />
    </Wrapper>
  );
};
