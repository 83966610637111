import imageCompression from "browser-image-compression";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { updateCourse, getCourse, deleteCourse } from "../../../api/admin";
import { Thumbnail } from "../../../components/common/Thumbnail";
import { TAdminCourse } from "../../../lib/types";
import { AdminWrapper } from "../../Wrapper.admin";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Spinner } from "../../../components/common/Spinner";

export const AdminViewCourse: React.FC = () => {
  const { id } = useParams();
  const [course, setCourse] = useState<TAdminCourse | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [message, setMessage] = useState<{ bg: string; msg: string } | null>(
    null
  );
  const loadingRef = useRef<LoadingBarRef>(null);

  useEffect(() => {
    (async () => {
      setCourse(await getCourse(id as string));
    })();
  }, [id]);

  const handleImageSelect = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setMessage(null);

    if (target.files && target.files[0]) {
      if (target.files[0]["type"].split("/")[0] !== "image") {
        e.preventDefault();

        return setMessage({
          bg: "bg-red-500",
          msg: "Invalid image selected",
        });
      }

      setSelectedImage(URL.createObjectURL(target.files[0]));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!course) {
      return;
    }

    e.preventDefault();
    setMessage(null);
    const form = e.target as any;

    const payload: {
      name?: string;
      description?: string;
      thumbnail?: string;
      price?: number;
      active?: boolean;
      instructor_id?: string;
      discount_id?: string | null;
    } = {};

    payload.name = form.name.value;
    payload.description = form.description.value;
    payload.price = form.price.value;
    payload.active = form.active.value === "yes" ? true : false;
    payload.instructor_id = form.instructor.value;
    payload.discount_id = form.discount_id || null;

    if (form.thumbnail.files.length > 0) {
      if (form.thumbnail.files[0]["type"].split("/")[0] !== "image") {
        return setMessage({
          bg: "bg-red-500",
          msg: "Invalid image selected",
        });
      }
      const options = {
        maxWidthOrHeight: 1280,
        maxSizeMB: 1,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(
        form.thumbnail.files[0],
        options
      );
      const imageDataURI = await imageCompression.getDataUrlFromFile(
        compressedImage
      );
      payload.thumbnail = imageDataURI;
    }

    try {
      loadingRef.current?.continuousStart(10, 15);
      await updateCourse(course.id, payload);
      loadingRef.current?.complete();
      window.location.reload();
    } catch (e) {
      setMessage({ bg: "bg-red-500", msg: "There was an error" });
    } finally {
      loadingRef.current?.complete();
    }
  };

  return (
    <AdminWrapper
      title="Admin"
      name={`Edit Course: ${course ? course.name : ""}`}
    >
      {course ? (
        <>
          <LoadingBar color="#f11946" ref={loadingRef} />
          <form onSubmit={handleSubmit} className="p-6 flex flex-col gap-6">
            {message && (
              <div
                className={`${message?.bg} rounded-md text-center py-4 text-lg text-white mb-8 container w-full`}
              >
                {message?.msg}
              </div>
            )}
            <div>
              <p className="font-semibold">Course Name</p>
              <input
                type="text"
                name="name"
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
                required
                defaultValue={course.name}
              />
            </div>
            <div>
              <p className="font-semibold">Description</p>
              <textarea
                name="description"
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
                required
                defaultValue={course.description}
              ></textarea>
            </div>
            <div>
              <p className="font-semibold">Price ৳</p>
              <input
                type="number"
                name="price"
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
                required
                defaultValue={course.price}
              />
            </div>
            <div>
              <p className="font-semibold">Is this course active?</p>
              <div className="flex gap-4">
                <span>
                  Yes{" "}
                  <input
                    type="radio"
                    name="active"
                    value="yes"
                    onChange={(e) => e.currentTarget.value}
                    className="bg-gray-50 px-4 py-2 mt-2 border-gray-400 border rounded-md"
                    defaultChecked={course.active}
                  />
                </span>
                <span>
                  No{" "}
                  <input
                    type="radio"
                    name="active"
                    value="no"
                    onChange={(e) => e.currentTarget.value}
                    className="bg-gray-50 px-4 py-2 mt-2 border-gray-400 border rounded-md"
                    defaultChecked={!course.active}
                  />
                </span>
              </div>
            </div>
            <div>
              <p className="font-semibold">Instructor ID</p>
              <input
                type="text"
                name="instructor"
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
                required
                defaultValue={course.instructor_id}
              />
            </div>
            <div>
              <p className="font-semibold">Discount ID</p>
              <input
                type="text"
                name="discount"
                onChange={(e) => e.currentTarget.value}
                className="bg-gray-50 px-4 py-2 mt-2 w-full border-gray-400 border rounded-md"
                defaultValue={course.discount_id ? course.discount_id : ""}
              />
            </div>
            <div>
              <p className="font-semibold">Course Thumbnail</p>
              <div className="flex items-center gap-4">
                <div className="bg-gray-50">
                  <input
                    type="file"
                    name="thumbnail"
                    accept="image/png, image/jpeg"
                    onChange={handleImageSelect}
                    id="thumbnailinp"
                    className="block w-full
                                            px-3
                                            py-1.5
                                            text-base
                                            font-normal
                                            text-gray-700
                                            bg-gray-50 bg-clip-padding
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  />
                </div>
                {selectedImage ? (
                  <>
                    <img
                      src={selectedImage}
                      className="h-[256px] mt-3 border border-gray-700"
                      alt=""
                    />
                  </>
                ) : (
                  <Thumbnail
                    className="h-[256px] mt-3 border border-gray-700"
                    courseID={course.id}
                  />
                )}
              </div>
              <div className="flex justify-between">
                <button
                  type="submit"
                  className="mt-6 w-60 text-lg px-4 py-2 border border-[#0087ca] transition-all duration-300 text-[#0087ca] hover:bg-[#0087ca] hover:text-white rounded-md"
                >
                  Submit Course
                </button>
                <button
                  type="button"
                  onClick={() => {
                    confirmAlert({
                      title: "Are you sure you want to delete this course?",
                      message: (course as any).name,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: async () => {
                            await deleteCourse((course as any).id);
                            window.location.href = "/admin/courses";
                          },
                        },
                        {
                          label: "No",
                          onClick: () => {},
                        },
                      ],
                      closeOnEscape: true,
                      closeOnClickOutside: true,
                    });
                  }}
                  className="mt-6 w-60 text-lg px-4 py-2 border border-red-400 transition-all duration-300 text-red-400 hover:bg-red-500 hover:text-white rounded-md"
                >
                  Delete Course
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <Spinner className="w-20 h-full" />
        </div>
      )}
    </AdminWrapper>
  );
};
